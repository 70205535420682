<template>
	<v-app>
		<Sidebar />
		<Topmenubar :breadcrumbs="this.breadcrumb" />
		<v-main>
			<Subheadertabs :subheadertabs="this.subheadertabs" ref="subheadertabs" />

			<v-container class="p-5" fluid>
				<v-card elevation="0" class="p-5 h-100">
					<h1 class="text-secondary">Bestelling #1234 details</h1>
					<p></p>

					<v-row>
						<v-col cols="12" md="6">
							<h3 class="text-primary">Algemeen</h3>
							<v-text-field :rules="nameRules" label="Kortingscode" outlined persistent-hint></v-text-field>
						</v-col>

						<v-col cols="12" md="6">
							<h3 class="text-primary">Contactgegevens</h3>
							<v-row dense>
								<v-col cols="6">
									<v-text-field :rules="nameRules" label="Voornaam" outlined persistent-hint></v-text-field>
								</v-col>
								<v-col cols="6">
									<v-text-field :rules="nameRules" label="Achternaam" outlined persistent-hint></v-text-field>
								</v-col>
							</v-row>
							<v-row dense>
								<v-col cols="12">
									<v-text-field :rules="nameRules" label="Bedrijfsnaam" outlined persistent-hint></v-text-field>
								</v-col>
							</v-row>

							<v-row dense>
								<v-col cols="4">
									<v-text-field :rules="nameRules" label="Adres" outlined persistent-hint></v-text-field>
								</v-col>

								<v-col cols="4">
									<v-text-field :rules="nameRules" label="Adres 2" outlined persistent-hint></v-text-field>
								</v-col>

								<v-col cols="4">
									<v-text-field :rules="nameRules" label="Postcode" outlined persistent-hint></v-text-field>
								</v-col>
							</v-row>

							<v-row dense>
								<v-col cols="4">
									<v-text-field :rules="nameRules" label="Plaats" outlined persistent-hint></v-text-field>
								</v-col>

								<v-col cols="4">
									<v-text-field :rules="nameRules" label="Provincie" outlined persistent-hint></v-text-field>
								</v-col>

								<v-col cols="4">
									<v-text-field :rules="nameRules" label="Land" outlined persistent-hint></v-text-field>
								</v-col>
							</v-row>

							<v-row dense>
								<v-col cols="6" class="py-0">
									<v-text-field class="py-0" :rules="nameRules" label="E-mailadres" outlined persistent-hint></v-text-field>
								</v-col>
								<v-col cols="6" class="py-0">
									<v-text-field class="py-0" :rules="nameRules" label="Telefoonnummer" outlined persistent-hint></v-text-field>
								</v-col>
							</v-row>
						</v-col>
					</v-row>

					<v-row>
						<v-col cols="12" class="ml-auto text-right">
							<v-btn color="secondary" right @click="submitdata" elevation="0"> Opslaan </v-btn>
						</v-col>
					</v-row>
				</v-card>
			</v-container>
		</v-main>
	</v-app>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Topmenubar from "@/components/Topmenubar.vue";
import Subheadertabs from "@/components/Subheadertabs.vue";

export default {
	components: {
		Sidebar,
		Topmenubar,
		Subheadertabs,
	},

	props: {
		coupon: Object,
		submit: Function,
	},

	methods: {
		submitdata() {
			if (this.submit != null) {
				this.submit();
			}
		},
	},

	data() {
		return {
			items: [
				"Vast winkelmand korting",
				"Procentuele korting",
				"Vast productkorting",
			],

			nameRules: [
				(v) => !!v || "Name is required",
				(v) => /^[a-zA-Z]{2,25}$/.test(v) || "Naam ongeldig",
			],

			subheadertabs: [
				{
					text: "Overzicht",
					href: `/order/${this.$route.params.id}`,
				},
				{
					text: "Bestel acties",
					href: `/order/${this.$route.params.id}/actions`,
				},
				{
					text: "Bestel tijdlijn",
					href: `/order/${this.$route.params.id}/timeline`,
				},
			],

			breadcrumb: [
				{
					text: "Bestellingen",
					disabled: false,
					href: "/orders",
				},
				{
					text: "Bestelling #1234",
					disabled: false,
					href: `/order/${this.$route.params.id}`,
				},
				{
					text: "Bestel acties",
					disabled: true,
					href: `/order/${this.$route.params.id}/actions`,
				},
			],
		};
	},
};
</script>

