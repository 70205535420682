<template>
	<v-app>
		<Sidebar />
		<Topmenubar :breadcrumbs="this.breadcrumb" />
		<v-main>
			<Subheadertabs :subheadertabs="this.subheadertabs" ref="subheadertabs" />

			<v-container class="p-5" fluid>
				<v-card elevation="0" class="p-5 h-100">
					<h1 class="text-primary">E-mails</h1>
					<p></p>
					<v-row>
						<v-col cols="12">
							<v-data-table :headers="headers" :items="emailssend" :items-per-page="25" @click:row="handleclick"> </v-data-table>
						</v-col>
					</v-row>
				</v-card>
			</v-container>
		</v-main>
	</v-app>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Topmenubar from "@/components/Topmenubar.vue";
import Subheadertabs from "@/components/Subheadertabs.vue";
export default {
	components: {
		Sidebar,
		Topmenubar,
		Subheadertabs,
	},

	props: {
		customer: Object,
		submit: Function,
	},

	methods: {
		submitdata() {
			if (this.submit != null) {
				this.submit();
			}
		},
		handleclick() {

		}
	},
	data() {
		return {
			headers: [
				{
					text: "Datum",
					align: "start",
					sortable: false,
					value: "date",
				},
				{ text: "Onderwerp", value: "subject" },
				{ text: "Naar emailadres", value: "email" },
			],
			emailssend: [
				{
					date: "1-1-2021 11:11",
					subject: "Dit is een mail onderwerp",
					email: "gianni@snelwebcenter.nl",
				},
			],
			breadcrumb: [
				{
					text: "Klanten",
					disabled: false,
					href: "/customers",
				},
				{
					text: `${this.customer.first_name} ${this.customer.last_name}`,
					disabled: false,
					href: `/customer/${this.$route.params.id}/emails`,
				},
			],
			subheadertabs: [
				{
					text: "Klantgegevens",
					href: `/customer/${this.$route.params.id}`,
				},
				{
					text: "Producten",
					href: `/customer/${this.$route.params.id}/products`,
				},
				{
					text: "Abonnementen",
					href: `/customer/${this.$route.params.id}/subscriptions`,
				},
				{
					text: "Facturen",
					href: `/customer/${this.$route.params.id}/invoices`,
				},
				{
					text: "E-mails",
					href: `/customer/${this.$route.params.id}/emails`,
				},
				// {
				//   text: "Tijdlijn",
				//   href: `/customer/${this.$route.params.id}/timeline`,
				// },
			],
		};
	},
};
</script>

