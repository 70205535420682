<template>
	<v-app>
		<Sidebar />
		<Topmenubar :breadcrumbs="this.breadcrumb" />
		<v-main>
			<Subheadertabs :subheadertabs="this.subheadertabs" ref="subheadertabs" />

			<v-container class="p-5" fluid>
				<v-card elevation="0" class="p-5 h-100">
					<h1 class="text-primary">Shortcodes</h1>
					<p>Je kan de onderstaande shortcodes plaatsen in en e-mail. Stortcodes zijn tags die in tekstvelden geplaatst kunnen worden om variabele teksten te tonen. De tags openen en sluiten met een haak.</p>

					<v-row>
						<v-col cols="12">
							<v-simple-table>
								<template v-slot:default>
									<thead>
										<tr>
											<th class="text-left">Omschrijving</th>
											<th class="text-left">Shortcode</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="item in desserts" :key="item.name">
											<td>{{ item.name }}</td>
											<td>{{ item.shortcode }}</td>
										</tr>
									</tbody>
								</template>
							</v-simple-table>
						</v-col>
					</v-row>
				</v-card>
			</v-container>
		</v-main>
	</v-app>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Topmenubar from "@/components/Topmenubar.vue";
import Subheadertabs from "@/components/Subheadertabs.vue";
export default {
	components: {
		Sidebar,
		Topmenubar,
		Subheadertabs,
	},

	data: () => ({
		breadcrumb: [
			{
				text: "Instellingen",
				disabled: false,
				href: "/settings",
			},
			{
				text: "Shortcodes",
				disabled: true,
				href: "/settings/shortcodes",
			},
		],
		subheadertabs: [
			{
				text: "Email instellingen",
				href: "/settings/emailsettings/",
			},
			{
				text: "Email templates",
				href: "/settings/emailtemplates",
			},
			{
				text: "Shortcodes",
				href: "/settings/shortcodes",
			},
		],
		desserts: [
			{
				name: 'Voornaam',
				shortcode: '[first_name]',
			},
			{
				name: 'Achternaam',
				shortcode: '[last_name]',
			},
			{
				name: 'Volledige naam',
				shortcode: '[full_name]',
			},
			{
				name: 'Telefoonnummer klant',
				shortcode: '[phone_customer]',
			},

		],
	}),
};
</script>

