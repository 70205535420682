<template>
	<v-app>
		<Sidebar />
		<Topmenubar :breadcrumbs="this.breadcrumb" />
		<v-main>
			<Subheadertabs :subheadertabs="this.subheadertabs" ref="subheadertabs" />

			<v-container class="p-5" fluid>
				<v-card elevation="0" class="p-5 h-100">
					<h1 class="text-primary">Algemeen</h1>
					<p></p>

					<v-row>
						<v-col cols="12">
							<v-text-field v-model="coupon.name" :rules="nameRules" label="Kortingscode" outlined persistent-hint></v-text-field>
						</v-col>
					</v-row>

					<v-row>
						<v-col cols="12">
							<v-textarea v-model="coupon.discription" outlined name="input-7-4" label="Beschrijving (optioneel)"></v-textarea>
						</v-col>
					</v-row>

					<v-row>
						<v-col cols="12">
							<v-select v-model="coupon.coupontype" :items="coupontype" label="Kortingstype" outlined> </v-select>
						</v-col>
					</v-row>

					<v-row>
						<v-col cols="12">
							<v-text-field v-model="coupon.value" label="Waarde van kortingscode" outlined></v-text-field>
						</v-col>
					</v-row>

					<v-row>
						<v-col cols="12">
							<v-checkbox v-model="coupon.freeshippingallowed" label="Sta gratis verzending toe" hint="Aanvinken als de kortingscode recht op gratis verzending geeft. Dit moet wel ingesteld zijn bij de checkout pagina waarop het gebruikt kan worden." persistent-hint></v-checkbox>
						</v-col>
					</v-row>

					<v-row>
						<v-col cols="12">
							<v-menu v-model="menu2" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
								<template v-slot:activator="{ on, attrs }">
									<v-text-field v-model="coupon.computedDateFormatted" label="Vervaldatum kortingscode" prepend-icon="mdi-calendar" outlined v-bind="attrs" v-on="on"></v-text-field>
								</template>
								<v-date-picker v-model="coupon.date" no-title @input="menu2 = false"></v-date-picker>
							</v-menu>
						</v-col>
					</v-row>

					<v-row>
						<v-col cols="12" class="ml-auto text-right">
							<v-btn color="secondary" right @click="submitdata" elevation="0"> Opslaan </v-btn>
						</v-col>
					</v-row>
				</v-card>
			</v-container>
		</v-main>
	</v-app>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Topmenubar from "@/components/Topmenubar.vue";
import Subheadertabs from "@/components/Subheadertabs.vue";

export default {
	components: {
		Sidebar,
		Topmenubar,
		Subheadertabs,
	},

	props: {
		coupon: Object,
		submit: Function,
	},

	methods: {
		submitdata() {
			if (this.submit != null) {
				this.submit();
			}
		},
	},

	data() {
		return {
			menu2: false,
			nameRules: [
				(v) => !!v || "Name is required",
				(v) => /^[a-zA-Z]{2,25}$/.test(v) || "Naam ongeldig",
			],

			subheadertabs: [
				{
					text: "Algemeen",
					href: `/coupon/${this.$route.params.id}`,
				},
				{
					text: "Gebruiksbeperking",
					href: `/coupon/${this.$route.params.id}/usage-restriction`,
				},
				{
					text: "Gebruikslimiet",
					href: `/coupon/${this.$route.params.id}/usage-limit`,
				},
			],

			breadcrumb: [
				{
					text: "Kortingscode",
					disabled: false,
					href: "/coupons",
				},
				{
					text: this.coupon.name,
					disabled: true,
					href: `/coupon/${this.$route.params.id}`,
				},
			],

			///ultra fake data
			coupontype: [
				"Vast winkelmand korting",
				"Procentuele korting",
				"Vast productkorting",
			],
		};
	},
};
</script>

