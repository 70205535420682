<template>
	<v-app>
		<Sidebar />
		<Topmenubar :breadcrumbs="this.breadcrumb" />
		<v-main>
			<Subheadertabs :subheadertabs="this.subheadertabs" ref="subheadertabs" :updateactivetab="updateactivetab" />

			<v-container class="p-5" fluid>
				<v-card elevation="0" class="p-5 h-100">
					<h1 class="text-primary">Tijdlijn</h1>
					<p></p>

					<v-row>
						<v-col cols="12">
							<v-textarea outlined name="input-7-4" label="Beschrijving (optioneel)" value=""></v-textarea>
						</v-col>
						<v-col cols="12" class="ml-auto text-right">
							<v-btn color="secondary" right @click="submitdata" elevation="0"> Plaats op de tijdlijn </v-btn>
						</v-col>
					</v-row>

					<v-row>
						<v-col cols="3"> </v-col>
						<v-col cols="6">
							<v-timeline :reverse="reverse" dense>
								<v-timeline-item v-for="n in 2" :key="n">
									<span slot="opposite">Tus eu perfecto</span>
									<v-card class="elevation-2">
										<v-card-text> Lorem ipsum dolor sit amet, no nam oblique veritus. Commune scaevola imperdiet nec ut, sed euismod convenire principes at. Est et nobis iisque percipit, an vim zril disputando voluptatibus, vix an salutandi sententiae. </v-card-text>
									</v-card>
								</v-timeline-item>
							</v-timeline>
						</v-col>
					</v-row>
				</v-card>
			</v-container>
		</v-main>
	</v-app>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Topmenubar from "@/components/Topmenubar.vue";
import Subheadertabs from "@/components/Subheadertabs.vue";
export default {
	components: {
		Sidebar,
		Topmenubar,
		Subheadertabs,
	},

	props: {
		customer: Object,
		submit: Function,
	},

	methods: {
		submitdata() {
			if (this.submit != null) {
				this.submit();
			}
		},
	},

	data() {
		return {
			breadcrumb: [
				{
					text: "Klanten",
					disabled: false,
					href: "/customers",
				},
				{
					text: `${this.customer.first_name} ${this.customer.last_name}`,
					disabled: false,
					href: `/customer/${this.$route.params.id}/timeline`,
				},
			],
			subheadertabs: [
				{
					text: "Klantgegevens",
					href: `/customer/${this.$route.params.id}`,
				},
				{
					text: "Producten",
					href: `/customer/${this.$route.params.id}/products`,
				},
				{
					text: "Abonnementen",
					href: `/customer/${this.$route.params.id}/subscriptions`,
				},
				{
					text: "Facturen",
					href: `/customer/${this.$route.params.id}/invoices`,
				},
				{
					text: "E-mails",
					href: `/customer/${this.$route.params.id}/emails`,
				},
				// {
				//   text: "Tijdlijn",
				//   href: `/customer/${this.$route.params.id}/timeline`,
				// },
			],
		};
	},
};
</script>

