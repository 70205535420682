<template>
  <v-app>
    <v-main>
      <v-container>
        <v-row>
          <v-col cols="1" md="3"></v-col>
          <v-col cols="10" md="6">
            <div v-if="thankyou.mediatype == 'image'">
              <v-img
                max-height="200"
                max-width="300"
                :src="thankyou.medialink"
                class="mx-auto mb-5"
              ></v-img>
            </div>
            <div v-if="thankyou.mediatype == 'video'">
              <video width="500" height="300" class="mx-auto d-block mb-5" controls>
                <source :src="mediatype" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div class="text-center mb-5">
              <h1 class="mb-2 text-primary" style="font-size: 2.5vw">
                {{ thankyou.Header }}
              </h1>
              <h2 class="mb-3">{{ thankyou.subheader }}</h2>
              <p class="mb-4">{{ thankyou.description }}</p>
              <v-btn
                color="secondary"
                right
                elevation="0"
                :href="thankyou.link"
              >
                Door naar de website
              </v-btn>
            </div>
            <v-card elevation="0" outlined class="p-5 h-90 overview">
              <h2 class="text-primary mb-5">Bestel overzicht</h2>
              <v-row>
                <v-col style="padding-top: 0; padding-bottom: 0" cols="6"
                  ><h3 class="text-primary">Naam:</h3></v-col
                ><v-col
                  style="padding-top: 0; padding-bottom: 0"
                  class="text-right"
                  cols="6"
                  ><p>{{ issuer.firstname }} {{ issuer.lastname }}</p></v-col
                >
                <v-col style="padding-top: 0; padding-bottom: 0" cols="6"
                  ><h3 class="text-primary">E-mailadres:</h3></v-col
                ><v-col
                  style="padding-top: 0; padding-bottom: 0"
                  class="text-right"
                  cols="6"
                  ><p>{{ issuer.email }}</p></v-col
                >
                <v-col style="padding-top: 0; padding-bottom: 0" cols="6"
                  ><h3 class="text-primary">Telefoonnummer:</h3></v-col
                ><v-col
                  style="padding-top: 0; padding-bottom: 0"
                  class="text-right"
                  cols="6"
                  ><p>{{ issuer.phonenumber }}</p></v-col
                >
                <v-col style="padding-top: 0; padding-bottom: 0" cols="6"
                  ><h3 class="text-primary">Bedrijfsnaam:</h3></v-col
                ><v-col
                  style="padding-top: 0; padding-bottom: 0"
                  class="text-right"
                  cols="6"
                  ><p>{{ issuer.companyname }}</p></v-col
                >
                <v-col style="padding-top: 0; padding-bottom: 0" cols="6"
                  ><h3 class="text-primary">E-mailadres:</h3></v-col
                ><v-col
                  style="padding-top: 0; padding-bottom: 30px"
                  class="text-right"
                  cols="6"
                  ><p>
                    {{ issuer.streetadress }}<br />{{ issuer.zipcode }}
                    {{ issuer.city }}
                  </p></v-col
                >
              </v-row>

              <div class="mt-3">
                <hr />
                <v-row no-gutters v-for="item in order" :key="item.name">
                  <v-col cols="6"
                    ><h4 class="text-primary cartitemname">
                      {{ item.name }}
                    </h4></v-col
                  ><v-col class="text-right" cols="6"
                    ><p>{{ item.price }},-</p></v-col
                  >
                </v-row>
                <hr />
              </div>
            </v-card>
          </v-col>
          <v-col cols="1" md="3"></v-col>
        </v-row>
        <v-row>
          <v-col cols="1" md="3"></v-col>
          <v-col cols="10" md="6"> 
              
          </v-col>
          <v-col cols="1" md="3"></v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
export default {
  components: {},

  props: {
    thankyou: Object,
    order: Object,
    issuer: Object,
  },

  data() {
    return {};
  },
};
</script>

<style>
#app {
  background-color: white !important;
}

@media screen and (max-width: 768px) {
  .overview p {
    font-size: 11px;
  }
  .overview h3.text-primary {
    font-size: 11px;
  }
  .overview .cartitemname {
    font-size: 9px;
  }
}
</style>