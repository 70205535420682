<template>
  <router-view :subscription="subscription" :submit="submit"></router-view>
</template>

<script>
export default {
  data() {
    return {
      subscription: {
        id: 1,
        name: "test abonnement",
        
      },
    };
  },
  methods: {
    submit() {
      //   HIER DAADWERKELIJK SUBMITTEN DOET NU NOG NIKS
    },
  },
};
</script>

<style>
</style>