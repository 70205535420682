<template>
  <v-app>
    <v-main color="white" class="bg-white">
      <v-container>
        <v-header class="bg-light">
          <v-row>
            <v-col cols="3">
              <v-btn elevation="0" color="white" href="https://kyano.app"
                >Terug naar kyano.app</v-btn
              >
            </v-col>
            <v-col cols="6">
              <v-img
                class="m-auto mb-3"
                max-width="165"
                max-height="31"
                src="../assets/kyano-account-logo-dark.svg"
              ></v-img
            ></v-col>
            <v-col cols="3"></v-col>
          </v-row>
        </v-header>
        <v-row class="py-15 mt-10">
          <v-col cols="12" lg="6" md="8" sm="10" class="text-center m-auto">
            <h1
              color="primary"
              class="text-primary display-2 font-weight-bold mb-3"
            >
              Starten met Kyano Orders
            </h1>

            <p class="text-lg-h6 font-weight-regular">
              Probeer 14 dagen gratis, 100% vrijblijvend.
            </p>
            <v-row class="my-8">
              <v-col cols="4">
                <v-text-field
                  label="Bedrijfsnaam"
                  :rules="[rules.required]"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  label="Voornaam"
                  :rules="[rules.required]"
                  outlined
                ></v-text-field
              ></v-col>
              <v-col cols="4">
                <v-text-field
                  label="Achternaam"
                  :rules="[rules.required]"
                  outlined
                ></v-text-field
              ></v-col>
              <v-col cols="6">
                <v-text-field
                  label="Telefoonnummer"
                  :rules="[rules.required]"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="E-mailadres"
                  :rules="[rules.required]"
                  outlined
                ></v-text-field
              ></v-col>
              <v-col cols="12" class="text-right">
                <p>
                  Direct starten zonder credit card gegevens of een betaling.
                </p>
                <v-btn
                    elevation="0"
                    large
                    class="text-right ml-auto"
                    :submit="submit"
                    color="secondary"
                    >Start mijn omgeving</v-btn
                  >
                </v-col
              >
            </v-row>
           
     
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
export default {
  data: () => ({
    rules: {
      required: (value) => !!value || "Verplicht.",
      min: (v) => v.length >= 8 || "Min 8 tekens",
      emailMatch: () => `The email and password you entered don't match`,
    },
  }),
};
</script>
<style>
</style>