<template>
	<div>
		<v-textarea label="Uitleg over het veld" :rules="config.rules.description" outlined v-model="editingitem.description"></v-textarea>
	</div>
</template>
<script>
import config from '@/config'

export default {
	props: {
		editingitem: Object
	},
	data() {
		return {
		}
	},
	computed: {
		config() {
			return config;
		}
	}
}
</script>