<template>
  <v-app>
    <v-main color="white" class="bg-white">
      <v-container>
        <v-header class="bg-light">
          <v-row>
            <v-col cols="3">
              <v-btn elevation="0" color="white" href="https://kyano.app"
                >Terug naar kyano.app</v-btn
              >
            </v-col>
            <v-col cols="6">
              <v-img
                class="m-auto mb-3"
                max-width="165"
                max-height="31"
                src="../assets/kyano-account-logo-dark.svg"
              ></v-img
            ></v-col>
            <v-col cols="3"></v-col>
          </v-row>
        </v-header>
        <v-row class="py-15 mt-10">
          <v-col cols="12" lg="6" md="8" sm="10" class="text-center m-auto">
            <h1
              color="primary"
              class="text-primary display-2 font-weight-bold mb-3"
            >
              Hi Gianni, Welkom
            </h1>

            <p class="text-lg-h6 font-weight-regular">
              Maak een wachtwoord aan en kies je eigen subdomein. <br>
Je hebt dan een (proef)account op maat.
            </p>
            <v-row class="my-8">
              <v-col cols="6">
                 <v-text-field
            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, rules.min]"
            :type="show2 ? 'text' : 'password'"
            name="input-10-2"
            label="Wachtwoord"
            hint="Minimaal 8 tekens"
            value="wqfasds"
            class="input-group--focused"
            @click:append="show2 = !show2"
            outlined
          ></v-text-field>
        
              </v-col>
              <v-col cols="6">
                 <v-text-field
            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, rules.min]"
            :type="show2 ? 'text' : 'password'"
            name="input-10-2"
            label="Herhaal wachtwoord"
            hint="Minimaal 8 tekens"
            value="wqfasds"
            class="input-group--focused"
            @click:append="show2 = !show2"
            outlined
          ></v-text-field></v-col>
              <v-col cols="12">
                <v-text-field                
                  label="Jouw subdomeinnaam"
                  :rules="[rules.required]"
                  persistent-hint
                  suffix=".kyano.app"
                  hint="Voorbeeld: Jouw bedrijfsnaam"
                  outlined                  
                ></v-text-field>
              </v-col>
             
              <v-col cols="12" class="text-right">
                <p>                 
                </p>
                <span class="mr-3">Stap 2/2</span>
                 <v-btn
                    elevation="0"
                    large
                    class="text-right ml-auto mr-3"
                    to="/o1"
                    color="light"
                    >Vorige stap</v-btn
                  >
                <v-btn
                    elevation="0"
                    large
                    class="text-right ml-auto"
                    :submit="submit"
                    color="secondary"
                    >Naar mijn omgeving</v-btn
                  >
                </v-col
              >
            </v-row>
           
     
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
export default {
  data: () => ({
    rules: {
      required: (value) => !!value || "Verplicht.",
      min: (v) => v.length >= 8 || "Min 8 tekens",
      emailMatch: () => `The email and password you entered don't match`,
    },
  }),
};
</script>
<style>
</style>