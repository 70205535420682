<template>
	<div>
		<v-text-field label="Ondertitel" outlined :rules="config.rules.subtitle" v-model="editingitem.subtitle"></v-text-field>
	</div>
</template>
<script>
import config from '@/config'

export default {
	props: {
		editingitem: Object
	},
	data() {
		return {
		}
	},
	computed: {
		config() {
			return config;
		}
	}
}

</script>