<template>
	<v-app>
		<Sidebar />
		<Topmenubar :breadcrumbs="this.breadcrumb" />
		<v-main>
			<Subheadertabs :subheadertabs="this.subheadertabs" ref="subheadertabs" />

			<v-container class="p-5" fluid>
				<v-card elevation="0" class="p-5 h-100">
					<h1 class="text-primary">Klant gegevens</h1>
					<p></p>

					<v-row>
						<v-col cols="12">
							<v-text-field v-model="customer.companyname" :rules="rules.name" label="Bedrijfsnaam" outlined persistent-hint></v-text-field>
						</v-col>
					</v-row>

					<v-row>
						<v-col cols="12" md="6"> <v-text-field v-model="customer.first_name" :rules="rules.name" label="Voornaam" outlined persistent-hint></v-text-field> </v-col
						><v-col cols="12" md="6">
							<v-text-field v-model="customer.last_name" :rules="rules.name" label="Achternaam" outlined persistent-hint></v-text-field>
						</v-col>
					</v-row>

					<v-row>
						<v-col cols="12">
							<v-text-field v-model="customer.emailadres" label="E-mailadres" outlined></v-text-field>
						</v-col>
					</v-row>

					<v-row>
						<v-col cols="12">
							<v-text-field v-model="customer.phonenumber" label="Telefoonnummer" outlined></v-text-field>
						</v-col>
					</v-row>

					<v-row>
						<v-col cols="12" md="4">
							<v-text-field v-model="customer.adres" label="Adres" outlined></v-text-field>
						</v-col>

						<v-col cols="12" md="4">
							<v-text-field v-model="customer.adres2" label="Adres 2" outlined></v-text-field>
						</v-col>

						<v-col cols="12" md="4">
							<v-text-field v-model="customer.postcode" label="Postcode" outlined></v-text-field>
						</v-col>

						<v-col cols="12" md="4">
							<v-text-field v-model="customer.location_state" label="Plaats" outlined></v-text-field>
						</v-col>
						<v-col cols="12" md="4">
							<v-text-field v-model="customer.province" label="Provincie" outlined></v-text-field>
						</v-col>
						<v-col cols="12" md="4">
							<v-text-field v-model="customer.country" label="Land" outlined></v-text-field>
						</v-col>
					</v-row>

					<v-row>
						<v-col cols="12" class="ml-auto text-right">
							<v-btn color="secondary" right @click="submitdata" elevation="0"> Opslaan </v-btn>
						</v-col>
					</v-row>
				</v-card>
			</v-container>
		</v-main>
	</v-app>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Topmenubar from "@/components/Topmenubar.vue";
import Subheadertabs from "@/components/Subheadertabs.vue";
import config from "@/config";

export default {
	components: {
		Sidebar,
		Topmenubar,
		Subheadertabs,
	},
	props: {
		customer: Object,
		submit: Function,
	},
	methods: {
		submitdata() {
			this.submit();
		},
	},
	data() {
		return {
			breadcrumb: [
				{
					text: "Klanten",
					disabled: false,
					href: "/customers",
				},
				{
					text: `${this.customer.first_name} ${this.customer.last_name}`,
					disabled: false,
					href: `/customer/${this.$route.params.id}`,
				},
			],
			subheadertabs: [
				{
					text: "Klantgegevens",
					href: `/customer/${this.$route.params.id}`,
				},
				{
					text: "Producten",
					href: `/customer/${this.$route.params.id}/products`,
				},
				{
					text: "Abonnementen",
					href: `/customer/${this.$route.params.id}/subscriptions`,
				},
				{
					text: "Facturen",
					href: `/customer/${this.$route.params.id}/invoices`,
				},
				{
					text: "E-mails",
					href: `/customer/${this.$route.params.id}/emails`,
				},
				// {
				//   text: "Tijdlijn",
				//   href: `/customer/${this.$route.params.id}/timeline`,
				// },
			],
			rules: {
				user: [
					value => !!value || 'Required.',
					value => (value && value.length >= 2) || 'Minimum 2 characters',
					value => (value && !(value.length > 50)) || 'Maximum 24 characters',
					value => (config.regex.user.test(value)) || 'Invalid characters',
				],
			},
		};
	},
};
</script>

