<template>
	<div class="kyano-field">
		<v-switch label="Component volledig breedte" v-model="editingitem.fullwidthcomponent"></v-switch>
	</div>
</template>
<script>

export default {
	props: {
		editingitem: Object
	},
	data() {
		return {
			
		}
	},
}
</script>