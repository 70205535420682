<template>
	<div>
		<v-card outlined class="pa-3 my-3 border" elevation="0">
			<v-radio-group v-model="editingitem.cycle" row label="Type product">
				<v-radio label="Eenmalig" value="onetime"></v-radio>
				<v-radio label="Abonnement" value="recurring"></v-radio>
			</v-radio-group>
			<div v-if="editingitem.cycle === 'onetime'">
				<v-row>
					<v-col cols="8">
						<v-text-field label="Keuze" v-model="editingitem.name" outlined></v-text-field>
					</v-col>
					<v-col cols="2">
						<v-text-field label="Bedrag" type="number" v-model="editingitem.cost" outlined></v-text-field>
					</v-col>
					<v-col cols="2">
						<v-select :items="vat" item-value="v" item-text="display" label="BTW" outlined v-model="editingitem.vat"></v-select>
					</v-col>
				</v-row>
			</div>
			<div v-else-if="editingitem.cycle === 'recurring'">
				<v-row>
					<v-col cols="6">
						<v-text-field label="Keuze" v-model="editingitem.name" outlined></v-text-field>
					</v-col>
					<v-col cols="2">
						<v-text-field label="Bedrag" type="number" v-model="editingitem.cost" outlined></v-text-field>
					</v-col>
					<v-col cols="2">
						<v-select :items="productcycle" item-value="v" item-text="display" label="Periode" outlined v-model="editingitem.period"></v-select>
					</v-col>
					<v-col cols="2">
						<v-select :items="vat" item-value="v" item-text="display" label="BTW" outlined v-model="editingitem.vat"></v-select>
					</v-col>
				</v-row>
			</div>
		</v-card>
	</div>
</template>
<script>
export default {
	props: {
		editingitem: Object,
	},
	data() {
		return {
			productcycle: [
				{
					v: 1,
					display: "1 Maand"
				},
				{
					v: 3,
					display: "1 kwartaal"
				},
				{
					v: 6,
					display: "1 half jaar"
				},
				{
					v: 12,
					display: "1 jaar"
				},
				{
					v: 24,
					display: "2 jaar"
				},
				{
					v: 36,
					display: "3 jaar"
				},
				{
					v: 48,
					display: "4 jaar"
				},
				{
					v: 60,
					display: "5 jaar"
				},
				{
					v: 72,
					display: "6 jaar"
				},
				{
					v: 84,
					display: "7 jaar"
				},
				{
					v: 96,
					display: "8 jaar"
				},
				{
					v: 108,
					display: "9 jaar"
				},
				{
					v: 120,
					display: "10 jaar"
				}

			],
			vat: [
				{
					v: 0,
					display: "0%"
				},
				{
					v: 9,
					display: "9%"
				},
				{
					v: 21,
					display: "21%"
				}
			],
		}
	}
}
</script>