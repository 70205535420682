<template>
	<v-app>
		<Navigations :breadcrumbs="this.breadcrumb()" />
		<v-main>
			<v-container class="p-5 kyano-bottom-save-bar-active" fluid>
				<v-row>
					<v-col cols="12" md="9">
						<v-card elevation="0" class="p-5 h-100" color="#fff">
							<h1 class="text-primary">Algemeen</h1>
							<p>Beheer hier je product groepen.</p>
							<v-row>
								<v-col cols="12">
									<v-text-field v-model="product.name" :rules="config.rules.name" label="Productnaam" outlined persistent-hint></v-text-field>
								</v-col>
								<v-col cols="12">
									<v-textarea outlined name="input-7-4" v-model="product.meta.description" :rules="config.rules.description" label="Omschrijving"></v-textarea>
								</v-col>
							</v-row>
						</v-card>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12" md="9">
						<v-card elevation="0" class="p-5 h-100" color="#fff">
							<h2 class="text-primary mb-3">Product groepen</h2>
							<v-expansion-panels v-model="selectedgroup" elevation="0" class="drag-inner-list">
								<draggable :list="product.meta.groups" class="list-group" handle=".handle">
									<transition-group>
										<v-expansion-panel v-for="(item, groupi) in product.meta.groups" :key="item.id" :title="item.name" class="drag-item rounded-lg outlined elevation-0 mb-3" style="background: #fff">
											<v-expansion-panel-header>
												<v-row>
													<v-col cols="4 d-flex">
														<v-btn class="handle" color="primary" icon>
															<v-icon>fal fa-grip-lines</v-icon>
														</v-btn>
														<h4 class="mt-auto mb-auto pl-3">
															{{ item.title }}
														</h4>
													</v-col>
													<v-col cols="8" class="text-right">
														<v-btn @click="removeGroup(groupi)" elevation="0" color="danger" icon><v-icon class="close">fal fa-trash-alt</v-icon></v-btn>
													</v-col>
												</v-row>
											</v-expansion-panel-header>
											<v-expansion-panel-content>
												<div v-for="(lign, idx) in item.text" :key="idx">
													{{ lign }}
												</div>
												<v-text-field label="Groeptitel" v-model="item.title" :rules="config.rules.name" outlined></v-text-field>
												<v-text-field v-model="item.description" :rules="config.rules.subtitle" label="Omschrijving" outlined></v-text-field>
												<v-row>
													<v-col cols="12">
														<h4>Groep velden</h4>
														<v-list class="my-0">
															<draggable :list="item.items" class="field-group" handle=".handle2">
																<v-list-item class="outline row my-0" v-for="(field, fi) in item.items" :key="fi">
																	<v-row>
																		<v-col cols="12">
																			<v-card elevation="0" color="#f5f5f9" class="border px-5 d-flex my-2">
																				<v-list-item-icon>
																					<v-btn class="handle2" color="primary" dark icon>
																						<v-icon color="primary">fal fa-grip-lines</v-icon>
																					</v-btn>
																				</v-list-item-icon>
																				<v-list-item-content>
																					<v-list-item-title v-text="field.name"></v-list-item-title>
																				</v-list-item-content>
																				<div class="my-auto">
																					<v-btn @click="removeField(fi)" elevation="0" color="danger" dark icon><v-icon color="danger" class="my-auto">fal fa-trash-alt</v-icon></v-btn>
																					<v-btn @click="editField(field)" elevation="0" color="primary" dark icon><v-icon color="primary" class="my-auto">fal fa-pen</v-icon></v-btn>
																				</div>
																			</v-card>
																		</v-col>
																	</v-row>
																</v-list-item>
															</draggable>
														</v-list>
													</v-col>
												</v-row>
												<v-row>
													<v-col cols="12">
														<v-card class="kyano-add-field-btn pa-4" @click="OpenFieldDrawer()" elevation="0">
															<div class="text-center text-primary"><v-icon color="primary">fal fa-plus</v-icon> Veld toevoegen</div>
														</v-card>
													</v-col>
												</v-row>
												<v-row>
													<v-col cols="12"><h4>Geavanceerd</h4> </v-col>
													<v-col cols="6">
														<v-text-field v-model="item.groupid" label="ID" outlined persistent-hint></v-text-field>
													</v-col>
													<v-col cols="6">
														<v-text-field v-model="item.groupclass" label="Class" outlined persistent-hint></v-text-field>
													</v-col>
												</v-row>
											</v-expansion-panel-content>
										</v-expansion-panel>
									</transition-group>
								</draggable>
							</v-expansion-panels>

							<v-btn class="mt-4" color="primary" right @click="addGroup()" elevation="0"><v-icon color="#fff" class="mr-2">fal fa-plus</v-icon> Groep toevoegen</v-btn>
							<Drawer class="width-60" :direction="'right'" :exist="true" ref="AddFieldDrawer">
								<v-container fluid class="pa-5 pa-md-15">
									<v-row>
										<v-col cols="6">
											<h2>Veld toevoegen</h2>
										</v-col>
										<v-col cols="6">
											<v-btn color="danger" large icon style="float: right" @click="CloseFieldDrawer()" elevation="0"> <v-icon>fal fa-times</v-icon> </v-btn>
										</v-col>
									</v-row>
									<v-row class="ma-4"></v-row>
									<v-row>
										<v-col cols="12">
											<v-select :items="types" item-text="dis" item-value="val" label="Veldsoort" outlined v-model="editingitem.type"></v-select>
											<v-select v-if="editingitem.type === 'radio'" :items="radiosubtypes" item-text="dis" item-value="val" label="Veldontwerp" outlined v-model="editingitem.subtype"></v-select>
											<v-select v-else-if="editingitem.type === 'formcontent'" :items="formcontentsubtypes" item-text="dis" item-value="val" label="Veldontwerp" outlined v-model="editingitem.subtype"></v-select>
											<SingleRadioToggle v-if="editingitem.subtype === 'singleradiotoggle'" :editingitem="editingitem" :conditionitems="conditionitems"></SingleRadioToggle>
											<SingleToggleTabs v-else-if="editingitem.subtype === 'singletoggletabs'" :editingitem="editingitem" :conditionitems="conditionitems"></SingleToggleTabs>
											<FormContent v-else-if="editingitem.subtype === 'formcontent'" :editingitem="editingitem" :conditionitems="conditionitems"></FormContent>
										</v-col>
									</v-row>
									<v-row>
										<v-col cols="12" class="ml-auto text-right">
											<v-btn color="secondary" right @click="addField()" elevation="0">Veld opslaan</v-btn>
										</v-col>
									</v-row>
								</v-container>
							</Drawer>
						</v-card>
					</v-col>
				</v-row>
			</v-container>
			<div class="kyano-bottom-save-bar">
				<div class="bar-container">
					<div class="ml-auto text-right">
						<v-btn color="secondary" right @click="submit" elevation="0">Opslaan</v-btn>
					</div>
				</div>
			</div>
		</v-main>
	</v-app>
</template>
<script>
// Single option components
import SingleRadioToggle from '../../components/form-fields/singleoption/SingleRadioToggle.vue';
import SingleToggleTabs from '@/components/form-fields/singleoption/SingleToggleTabs.vue';

// Form content components
import FormContent from '@/components/form-fields/formcontent/FormContent.vue';

import Navigations from "@/components/Navigations.vue";
import draggable from 'vuedraggable';
import Drawer from '@/components/Drawer.vue';
import config from '@/config';
import axios from 'axios';
import { mapGetters } from 'vuex';

export default {
	components: {
		Navigations,
		draggable,
		Drawer,
		SingleRadioToggle,
		SingleToggleTabs,
		FormContent,
	},
	data() {
		return {
			options: {
				dropzoneSelector: ".drag-inner-list",
				draggableSelector: ".drag-item"
			},
			product: {
				id: -1,
				name: "",
				last_modified: 0,
				shop: 0,
				stock: 0,
				meta: {
					groups: []
				}
			},
			conditionitems: [],
			dragging: false,
			ConditionsSelect: ['AND', 'OR'],
			types: [
				{
					val: 'radio',
					dis: 'Enkele optie'
				},
				{
					val: 'formcontent',
					dis: 'Formulier content'
				}
			],
			radiosubtypes: [
				{
					val: 'singleradiotoggle',
					dis: 'Knoppen'
				},
				{
					val: 'singletoggletabs',
					dis: 'Toggle tabs'
				},
			],
			formcontentsubtypes: [
				{
					val: 'formcontent',
					dis: 'Content'
				},
			],
			cycles: ['Monthly', 'Yearly'],
			selectedgroup: 0,
			newitem: true,
			editingitem: {
				name: '',
				description: '',
				subtitle: '',
				items: [],
				groupid: '',
				groupclass: '',
				default: false,
				conditions: [[]],
				type: 'text',
				subtype: 'text',
				row: "layout-ver",
				placeholder: '',
				useconditions: false,
				layout: 0
			}
		}
	},
	computed: {
		...mapGetters({ token: 'gettoken' }),
		draggingInfo() {
			return this.dragging ? 'under drag' : '';
		},
		config() {
			return config;
		}
	},
	methods: {
		breadcrumb() {
			return [
				{
					text: 'Producten',
					disabled: false,
					href: '/products',
				},
				{
					text: this.product.name !== null ? this.product.name : "",
					disabled: true,
					href: `/product/${this.$route.params.id}`,
				},
			]
		},
		removeGroup(idx) {
			this.product.meta.groups.splice(idx, 1);
			this.setconditionsitems();
		},
		removeField(idx) {
			this.product.meta.groups[this.selectedgroup].items.splice(idx, 1);
			this.setconditionsitems();
		},
		addField() {
			if (this.newitem) {
				this.product.meta.groups[this.selectedgroup].items.push(this.editingitem);
			}
			this.editingitem = this.__getdefaultitem()
			this.newitem = true;
			this.$refs.AddFieldDrawer.close();
			this.setconditionsitems();
		},
		addGroup() {
			this.product.meta.groups.push({
				title: 'Nieuwe groep',
				id: Math.random().toString(36).substr(2, 7),
				description: '',
				items: [],
				groupid: '',
				groupclass: '',
			});
		},
		OpenFieldDrawer() {
			this.editingitem = this.__getdefaultitem();
			this.editingitem.id = Math.random().toString(36).substr(2, 7)
			this.$refs.AddFieldDrawer.open();
		},
		CloseFieldDrawer() {
			this.editingitem = this.__getdefaultitem()
			this.$refs.AddFieldDrawer.close();
			this.setconditionsitems();
		},
		editField(fielddata) {
			this.editingitem = fielddata;
			this.newitem = false;
			this.$refs.AddFieldDrawer.open();
		},
		submit() {
			axios.patch(`${config.url.api}/v1/orders/product/${this.product.id}`, this.product, {
				headers: {
					Authorization: `user ${this.token}`
				}
			}).then(res => {
				if (res.data.succes) {
					this.$toast.success("Veranderingen opgeslagen", {
						position: "top-right",
						timeout: 5000,
						closeOnClick: true,
						pauseOnFocusLoss: true,
						pauseOnHover: true,
						draggable: true,
						draggablePercent: 0.6,
						showCloseButtonOnHover: false,
						hideProgressBar: true,
						closeButton: "button",
						icon: true,
						rtl: false
					});
				} else {
					this.$toast.error("Fout bij het opslaan van de veranderingen", {
						position: "top-right",
						timeout: 5000,
						closeOnClick: true,
						pauseOnFocusLoss: true,
						pauseOnHover: true,
						draggable: true,
						draggablePercent: 0.6,
						showCloseButtonOnHover: false,
						hideProgressBar: true,
						closeButton: "button",
						icon: true,
						rtl: false
					});
				}
			}).catch(err => {
				if (err) throw err;
				this.$toast.error("Fout bij het opslaan van de veranderingen", {
					position: "top-right",
					timeout: 5000,
					closeOnClick: true,
					pauseOnFocusLoss: true,
					pauseOnHover: true,
					draggable: true,
					draggablePercent: 0.6,
					showCloseButtonOnHover: false,
					hideProgressBar: true,
					closeButton: "button",
					icon: true,
					rtl: false
				});
			})
		},
		setconditionsitems() {
			let tmp = [];
			this.product.meta.groups.forEach(grp => {
				grp.items.forEach(itm => {
					if (itm.type === 'radio') {
						tmp.push({
							id: itm.id,
							type: itm.type,
							subtype: itm.subtype,
							group: grp.id,
							itms: itm.items,
							name: `${itm.name} (${grp.title})`
						})
					} else {
						tmp.push({
							id: itm.id,
							type: itm.type,
							subtype: itm.subtype,
							group: grp.id,
							name: `${itm.name} (${grp.title})`
						})
					}
				})
			});
			this.conditionitems = tmp;
		},
		__getdefaultitem() {
			return {
				name: '',
				description: '',
				subtitle: '',
				items: [],
				groupid: '',
				groupclass: '',
				default: false,
				conditions: [[]],
				type: 'text',
				subtype: 'text',
				row: "layout-ver",
				placeholder: '',
				useconditions: false,
				layout: 0
			}
		},
		sleep(ms) {
			return new Promise(resolve => setTimeout(resolve, ms));
		},
		wait() {
			return new Promise(() => {
				if (this.token === null) {
					this.sleep(200).then(() => {
						if (this.token === null) {
							this.wait();
						} else {
							this.load()
						}
					})
				} else {
					this.load()
				}
			});
		},
		load() {
			axios.get(`${config.url.api}/v1/orders/product/${this.$route.params.id}`, {
				headers: {
					Authorization: `user ${this.token}`
				}
			}).then((res) => {
				this.product = res.data;
				this.setconditionsitems();
			})
		}
	},
	created() {
		this.wait();
		this.editingitem = this.__getdefaultitem();
	}
};
</script>
<style scoped>
@keyframes nodeInserted {
    from { opacity: 0.2; }
    to { opacity: 0.8; }
}

.item-dropzone-area {
    height: 2rem;
    background: #888;
    opacity: 0.8;
    animation-duration: 0.5s;
    animation-name: nodeInserted;
}
.kyano-add-field-btn{
	background: #011a6215;
	border: 1px dashed #011a62
}
.field-group {
	width: 100%;
}
.button {
	margin-top: 35px;
}
.list-group {
	width: 100%;
}
.handle {
	float: left;
	padding-top: 8px;
	padding-bottom: 8px;
}
input {
	display: inline-block;
	width: 50%;
}
.text {
	margin: 20px;
}

.v-expansion-panel::before {
   box-shadow: none !important;
}

.kyano-bottom-save-bar{
    position: fixed;
    bottom: 0px;
    display: block;
    right: 0px;
    left: 0px;
    width: 100%;
    padding: 20px;
    background: #fff;
    border-top: 1px solid #d0d0d080;
    z-index: 5
}


.kyano-bottom-save-bar .bar-container{
    max-width: 100%;
    width: 1200px;
    padding-left: 15px;
    padding-right: 15px;
    margin: 0 auto;
}
.kyano-bottom-save-bar-active{
	padding-bottom: 100px;
}
</style>
