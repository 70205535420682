<template>
	<v-app>
		<Sidebar />
		<Topmenubar :breadcrumbs="this.breadcrumb" />
		<v-main>
			<Subheadertabs :subheadertabs="this.subheadertabs" ref="subheadertabs" />

			<v-container class="p-5" fluid>
				<v-card elevation="0" class="p-5 h-100">
					<h1 class="text-primary">Abonnementen</h1>
					<p></p>

					<v-row>
						<v-col cols="12">
							<v-data-table :headers="headers" :items="subscriptions" :items-per-page="25" @click:row="handleClick"> </v-data-table>
						</v-col>
					</v-row>
				</v-card>
			</v-container>
		</v-main>
	</v-app>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Topmenubar from "@/components/Topmenubar.vue";
import Subheadertabs from "@/components/Subheadertabs.vue";
export default {
	components: {
		Sidebar,
		Topmenubar,
		Subheadertabs,
	},

	props: {
		customer: Object,
		submit: Function,
	},

	methods: {
		submitdata() {
			if (this.submit != null) {
				this.submit();
			}
		},
		handleClick(v) {
			this.$router.push(v.to);
		},
	},

	data() {
		return {
			breadcrumb: [
				{
					text: "Klanten",
					disabled: false,
					href: "/customers",
				},
				{
					text: `${this.customer.first_name} ${this.customer.last_name}`,
					disabled: false,
					href: `/customer/${this.$route.params.id}/subscriptions`,
				},
			],
			subheadertabs: [
				{
					text: "Klantgegevens",
					href: `/customer/${this.$route.params.id}`,
				},
				{
					text: "Producten",
					href: `/customer/${this.$route.params.id}/products`,
				},
				{
					text: "Abonnementen",
					href: `/customer/${this.$route.params.id}/subscriptions`,
				},
				{
					text: "Facturen",
					href: `/customer/${this.$route.params.id}/invoices`,
				},
				{
					text: "E-mails",
					href: `/customer/${this.$route.params.id}/emails`,
				},
			],
			headers: [
				{
					text: "Abonnement",
					align: "start",
					sortable: false,
					value: "subscription",
				},
				{ text: "Totaal", value: "total" },
				{ text: "Startdatum", value: "start_date" },
				{ text: "Einddatum proefperiode", value: "trail_end_date" },
				{ text: "Volgende betaling", value: "next_payment" },
				{ text: "Vervaldatum", value: "due_date" },
				{ text: "Status", value: "status" },
			],
			subscriptions: [
				{
					subscription: "#12 - Gianni Hesseling",
					total: "€25,00 / week",
					start_date: "10%",
					description: "Dit is en omschrijving.",
					limit: "4/100",
					due_date: "22/02/2022",
					status: "Actief",
					to: `/subscription/1`,
				},
			],
		};
	},
};
</script>

