<template>
	<v-app>
		<Sidebar />
		<Topmenubar :breadcrumbs="this.breadcrumb" />

		<v-main>
			<Subheadertabs :subheadertabs="this.subheadertabs" ref="subheadertabs" :updateactivetab="updateactivetab" />

			<v-container class="p-5" fluid>
				<v-card elevation="0" class="p-5 h-100">
					<h1 class="text-primary">Analytics</h1>
					<p>Let op: Plaats de trackcode ook op je eigen bedanktpagina’s.</p>

					<v-row>
						<v-col cols="12">
							<v-select v-model="setting.setting_analytics" :items="analytics" item-text="name" item-value="id" label="Google" return-object outlined> </v-select>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12">
							<div v-if="`${setting.setting_analytics.id}` === '1'">
								<v-text-field v-model="setting.googleanalytics" label="Google analytics" outlined></v-text-field>
							</div>
							<div v-if="`${setting.setting_analytics.id}` === '2'">
								<v-text-field v-model="setting.googletagmanager" label="Google tagmanager" outlined></v-text-field>
							</div>
						</v-col>
					</v-row>

					<v-divider class="mb-10 mt-3" />
					<v-row>
						<v-col cols="12" md="4">
							<h3>Facebook pixels</h3>
						</v-col>
					</v-row>

					<v-row>
						<v-col cols="12">
							<v-text-field v-model="setting.facebookpixel" label="Facebook pixels code" outlined></v-text-field>
						</v-col>
					</v-row>

					<v-row>
						<v-col cols="12" class="ml-auto text-right">
							<v-btn color="secondary" right @click="submit" elevation="0"> Opslaan </v-btn>
						</v-col>
					</v-row>
				</v-card>
			</v-container>
		</v-main>
	</v-app>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Topmenubar from "@/components/Topmenubar.vue";
import Subheadertabs from "@/components/Subheadertabs.vue";

export default {
	components: {
		Sidebar,
		Topmenubar,
		Subheadertabs,
	},

	props: {
		setting: Object,
		submit: Function,
	},
	data() {
		return {
			breadcrumb: [
				{
					text: "Instellingen",
					disabled: false,
					href: "/settings",
				},
				{
					text: "Koppelingen",
					disabled: true,
					href: "/settings",
				},
				{
					text: "Loading",
					disabled: true,
					href: "#",
				},
			],
			subheadertabs: [
				{
					text: "Webhooks",
					href: "/settings/webhooks"
				},
				{
					text: "Analytics",
					href: "/settings/analytics"
				},
				{
					text: "Betaalmethodes",
					href: "/settings/paymentmethodes"
				}
			],

			analytics: [
				{
					id: "0",
					name: "Uitgeschakeld",
				},
				{
					id: "1",
					name: "Google analytics",
				},
				{
					id: "2",
					name: "Google tagmanager",
				},
			],
		};
	},
	methods: {
		updateactivetab(v, o) {
			var res = this.subheadertabs.filter((item) => item.href === v);
			if (res.length > 0) {
				var res2 = this.breadcrumb.filter((item) => item.href === o);
				res2[0].text = res[0].text;
				res2[0].href = res[0].href;
			}
		},
	},
};
</script>
