<template>
	<v-app>
		<Sidebar />
		<Topmenubar :breadcrumbs="this.breadcrumb" />
		<v-main>
			<Subheadertabs :subheadertabs="this.subheadertabs" ref="subheadertabs" />

			<v-container class="p-5" fluid>
				<v-card elevation="0" class="p-5 h-100">
					<h1 class="text-primary">E-mail instellingen</h1>
					<p>Pas hier de email instellingen aan voor Kyano orders.</p>

					<v-row>
						<v-col cols="12">
							<v-checkbox v-model="setting.emailsforcollection" label="Factuurmails voor automatisch incasso’s uitschakelen"></v-checkbox>
							<v-checkbox v-model="setting.emailsforneworders" label="Factuurmails voor nieuwe bestellingen uitschakelen"></v-checkbox>
							<v-checkbox v-model="setting.emailsforpaymentreminders" label="Mails voor betaalherinneringen uitschakelen"></v-checkbox>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12">
							<v-text-field v-model="setting.customemailadres" label="E-mails versturen vanuit" hint="Vanuit welke e-mailadres wil je mailen." outlined persistent-hint></v-text-field>
						</v-col>
					</v-row>

					<v-row>
						<v-col cols="12" class="ml-auto text-right">
							<v-btn color="secondary" right @click="submitdata" elevation="0"> Opslaan </v-btn>
						</v-col>
					</v-row>
				</v-card>
			</v-container>
		</v-main>
	</v-app>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Topmenubar from "@/components/Topmenubar.vue";
import Subheadertabs from "@/components/Subheadertabs.vue";
export default {
	components: {
		Sidebar,
		Topmenubar,
		Subheadertabs,
	},

	props: {
		setting: Object,
		submit: Function,
	},

	data: () => ({
		breadcrumb: [
			{
				text: "Instellingen",
				disabled: false,
				href: "/settings",
			},
			{
				text: "E-mail",
				disabled: true,
				href: "/settings/emailsettings",
			},
		],
		subheadertabs: [
			{
				text: "Email instellingen",
				href: "/settings/emailsettings/",
			},
			{
				text: "Email templates",
				href: "/settings/emailtemplates",
			},
			{
				text: "Shortcodes",
				href: "/settings/shortcodes",
			},
		],
	}),
	methods: {
		submitdata() {
			if (this.submit != null) {
				this.submit();
			}
		}
	}
};
</script>

