<template>
  <v-app>
    <Sidebar />
    <Topmenubar :breadcrumbs="this.breadcrumb" />

    <v-main>
      <Subheadertabs :subheadertabs="this.subheadertabs" />

      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Topmenubar from "@/components/Topmenubar.vue";
import Subheadertabs from "@/components/Subheadertabs.vue";
export default {
  components: {
    Sidebar,
    Topmenubar,
    Subheadertabs,
  },

  data: () => ({
    breadcrumb: [
      {
        text: "Instellingen",
        disabled: false,
        href: "/settings",
      },
      {
        text: "Voorkeuren",
        disabled: true,
        href: "/settings/preferences",
      },
    ],
     subheadertabs: [
        {
          text: "Gegevens",
          href: "/settings/general",
        },
        {
          text: "Betalingen",
          href: "/settings/payments",
        },
        {
          text: "Algemene voorwaarden",
          href: "/settings/terms",
        },
        {
          text: "Domeinnaam",
          href: "/settings/domainname",
        },
        {
          text: "Voorkeuren",
          href: "/settings/preferences",
        },
        {
          text: "Widgets",
          href: "/settings/widgets",
        }
      ],
  }),
};
</script>

