<template>
	<v-app>
		<Sidebar />
		<Topmenubar :breadcrumbs="this.breadcrumb" />
		<v-main>
			<Subheadertabs :subheadertabs="this.subheadertabs" ref="subheadertabs" />

			<v-container class="p-5" fluid>
				<v-card elevation="0" class="p-5 h-100">
					<h1 class="text-primary">Producten</h1>
					<p></p>

					<v-row>
						<v-col cols="12">
							<v-data-table :headers="headers" :items="products" :items-per-page="25" @click:row="handleClick"> </v-data-table>
						</v-col>
					</v-row>
				</v-card>
			</v-container>
		</v-main>
	</v-app>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Topmenubar from "@/components/Topmenubar.vue";
import Subheadertabs from "@/components/Subheadertabs.vue";
export default {
	components: {
		Sidebar,
		Topmenubar,
		Subheadertabs,
	},

	props: {
		customer: Object,
		submit: Function,
	},

	methods: {
		submitdata() {
			if (this.submit != null) {
				this.submit();
			}
		},
		handleClick(v) {
			this.$router.push(v.to);
		},
	},

	data() {
		return {
			breadcrumb: [
				{
					text: "Klanten",
					disabled: false,
					href: "/customers",
				},
				{
					text: `${this.customer.first_name} ${this.customer.last_name}`,
					disabled: false,
					href: `/customer/${this.$route.params.id}/products`,
				},
			],
			subheadertabs: [
				{
					text: "Klantgegevens",
					href: `/customer/${this.$route.params.id}`,
				},
				{
					text: "Producten",
					href: `/customer/${this.$route.params.id}/products`,
				},
				{
					text: "Abonnementen",
					href: `/customer/${this.$route.params.id}/subscriptions`,
				},
				{
					text: "Facturen",
					href: `/customer/${this.$route.params.id}/invoices`,
				},
				{
					text: "E-mails",
					href: `/customer/${this.$route.params.id}/emails`,
				},
			],

			headers: [
				{
					text: "ID",
					align: "start",
					sortable: false,
					value: "id",
				},
				{ text: "Naam", value: "name" },
				{ text: "Prijs", value: "price" },
				{ text: "Bestellingen", value: "orders" },
				{ text: "Abonnementen", value: "subscription" },

			],
			products: [
				{
					id: 1,
					name: "gianni hesseling",
					price: "€200",
					orders: 4,
					subscription: 'Actief',
					to: `/product/1`
				}
			],
		};
	},
};
</script>

