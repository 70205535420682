<template>
	<div>
		<h4 class="mb-2">Algemeen</h4>
		<!-- Field title -->
		<FieldTitle :editingitem="editingitem" />
		<!-- Field subtitle -->
		<FieldSubtitle :editingitem="editingitem" />
		<!-- Field information -->
		<FieldInfo :editingitem="editingitem" />
		<h4 class="mb-2">Voer een keuze in</h4>
		<!-- Choices fields with price, cycle and Vat-->
		<ChoosePrice v-for="item in editingitem.items" :key="item.id" :editingitem="item" />
		<v-row v-if="editingitem.items.length < 20">
			<v-col cols="12" class="ml-auto text-right">
				<v-btn color="light" right @click="addchoice" elevation="0">Keuze toevoegen</v-btn>
			</v-col>
		</v-row>
		<!-- default value in select for choices-->
		<DefaultVal :editingitem="editingitem" />
		<!-- Field layout; vertical/horizontal-->
		<Layout :editingitem="editingitem" />
		<FieldFullWidth :editingitem="editingitem" />
		<!--Is this field Required? true/false-->
		<Required :editingitem="editingitem" />
		<!-- Conditions -->
		<Conditions :editingitem="editingitem" :conditionitems="conditionitems"/>
		<!-- Advanced fields; ID and class-->
		<Advanced :editingitem="editingitem" />
	</div>
</template>
<script>

import Required from "../../form-components/Required.vue";
import Layout from "../../form-components/Layout.vue";
import ChoosePrice from "../../form-components/ChoosePrice.vue";
import Conditions from "../../form-components/Conditions.vue";
import Advanced from "../../form-components/Advanced.vue";
import FieldTitle from "../../form-components/FieldTitle.vue";
import FieldSubtitle from "../../form-components/FieldSubtitle";
import FieldInfo from "../../form-components/FieldInfo";
import FieldFullWidth from "../../form-components/FieldFullWidth.vue";

export default {
	components: {
		Required,
		Layout,
		ChoosePrice,
		Conditions,
		Advanced,
		FieldTitle,
		FieldSubtitle,
		FieldInfo,
		FieldFullWidth
	},
	props: {
		editingitem: Object,
		conditionitems: Array
	},
	methods: {
		addchoice() {
			this.editingitem.items.push({
				id: Math.random().toString(36).substr(2, 7),
				cycle: 'onetime',
				period: 0,
				cost: 0.00,
				name: "",
			})
		}
	}
}
</script>