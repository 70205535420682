<template>
	<div>						
		<v-text-field label="Titel" :rules="rules.name" outlined v-model="editingitem.name"></v-text-field>
	</div>
</template>
<script>
import config from '@/config'

export default {
	props: {
		editingitem: Object
	},
	data() {
		return {	
			rules: {
				name: [
					value => (value && !(value.length > 50)) || 'Maximum 50 characters',
					value => (config.regex.product_name.test(value)) || 'Invalid characters',
				],	
			}
		}
	},
}
</script>