<template>
	<v-app>
		<Sidebar />
		<Topmenubar :breadcrumbs="this.breadcrumb" />

		<v-main>
			<Subheadertabs :subheadertabs="this.subheadertabs" ref="subheadertabs" :updateactivetab="updateactivetab" />

			<v-container class="p-5" fluid>
				<v-card elevation="0" class="p-5 h-100">
					<h1 class="text-primary">Gegevens</h1>
					<p>Velden met een * zijn verplicht.</p>

					<v-row>
						<v-col cols="12">
							<v-text-field v-model="setting.shopname" label="Winkelnaam" outlined></v-text-field>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12">
							<v-text-field v-model="setting.companyname" label="Bedrijfsnaam" outlined></v-text-field>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12">
							<v-text-field v-model="setting.phonenumber" label="Telefoonnummer" outlined></v-text-field>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" md="6">
							<v-text-field v-model="setting.emailadres" label="E-mailadres" outlined></v-text-field>
						</v-col>

						<v-col cols="12" md="6">
							<v-text-field v-model="setting.emailadres_for_notifications" label="E-mailadres voor meldingen" outlined></v-text-field>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12">
							<v-text-field v-model="setting.website" label="Website" outlined></v-text-field>
						</v-col>
					</v-row>

					<v-divider class="mb-10 mt-3" />
					<v-row>
						<v-col cols="12" md="4">
							<h3>Adres</h3>
						</v-col>
					</v-row>

					<v-row>
						<v-col cols="12" md="4">
							<v-text-field v-model="setting.adres" label="Adres" outlined></v-text-field>
						</v-col>
						<v-col cols="12" md="4">
							<v-text-field v-model="setting.adres2" label="Adres 2" outlined></v-text-field>
						</v-col>

						<v-col cols="12" md="4">
							<v-text-field v-model="setting.postcode" label="Postcode" outlined></v-text-field>
						</v-col>
					</v-row>

					<v-row>
						<v-col cols="12" md="6">
							<v-text-field v-model="setting.location_state" label="Plaats" outlined></v-text-field>
						</v-col>

						<v-col cols="12" md="6">
							<v-text-field v-model="setting.country" label="Land" outlined></v-text-field>
						</v-col>
					</v-row>

					<v-divider class="mb-10 mt-3" />
					<v-row>
						<v-col cols="12" md="4">
							<h3>Bedrijfsgegevens</h3>
						</v-col>
					</v-row>

					<v-row>
						<v-col cols="12">
							<v-text-field v-model="setting.btw_id" label="Btw-id" outlined></v-text-field>
						</v-col>

						<v-col cols="12">
							<v-text-field v-model="setting.kvk_nr" label="KvK-nummer" outlined></v-text-field>
						</v-col>
						<v-col cols="12">
							<v-text-field v-model="setting.iban" label="IBAN" outlined></v-text-field>
						</v-col>
					</v-row>

					<v-divider class="mb-10 mt-3" />
					<v-row>
						<v-col cols="12" md="4">
							<h3>Social media</h3>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" md="4">
							<v-text-field v-model="setting.facebook_url" label="Facebook" outlined></v-text-field>
						</v-col>

						<v-col cols="12" md="4">
							<v-text-field v-model="setting.twitter_url" label="Twitter" outlined></v-text-field>
						</v-col>
						<v-col cols="12" md="4">
							<v-text-field v-model="setting.linkedin_url" label="Linkedin" outlined></v-text-field>
						</v-col>
						<v-col cols="12" md="4">
							<v-text-field v-model="setting.instagram_url" label="Instagram" outlined></v-text-field>
						</v-col>
						<v-col cols="12" md="4">
							<v-text-field v-model="setting.pinterest_url" label="Pinterest" outlined></v-text-field>
						</v-col>
					</v-row>

					<v-divider class="mb-10 mt-3" />
					<v-row>
						<v-col cols="12" md="6">
							<v-file-input v-model="setting.logo" label="Upload logo" outlined prepend-icon="mdi-image"></v-file-input>
						</v-col>

						<v-col cols="12" md="6">
							<v-file-input v-model="setting.favicon" label="Upload favicon" outlined prepend-icon="mdi-image"></v-file-input>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" class="ml-auto text-right">
							<v-btn color="secondary" right @click="submit" elevation="0"> Opslaan </v-btn>
						</v-col>
					</v-row>
				</v-card>
			</v-container>
		</v-main>
	</v-app>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Topmenubar from "@/components/Topmenubar.vue";
import Subheadertabs from "@/components/Subheadertabs.vue";

export default {
	components: {
		Sidebar,
		Topmenubar,
		Subheadertabs,
	},
	props: {
		setting: Object,
		submit: Function,
	},


	data() {
		return {
			breadcrumb: [
				{
					text: "Instellingen",
					disabled: false,
					href: "/settings",
				},
				{
					text: "Loading",
					disabled: true,
					href: "#",
				},
			],
			subheadertabs: [
				{
					text: "Gegevens",
					href: "/settings/general",
				},
				{
					text: "Betalingen",
					href: "/settings/payments",
				},
				{
					text: "Algemene voorwaarden",
					href: "/settings/terms",
				},
				{
					text: "Domeinnaam",
					href: "/settings/domainname",
				},
				{
					text: "Voorkeuren",
					href: "/settings/preferences",
				},
				{
					text: "Widgets",
					href: "/settings/widgets",
				},
			],
		};
	},
	methods: {
		updateactivetab(v, o) {
			var res = this.subheadertabs.filter((item) => item.href === v);
			if (res.length > 0) {
				var res2 = this.breadcrumb.filter((item) => item.href === o);
				res2[0].text = res[0].text;
				res2[0].href = res[0].href;
			}
		},
	},
};
</script>
