<template>
	<v-app>
		<Sidebar />
		<Topmenubar :breadcrumbs="this.breadcrumb" />
		<v-main>
			<v-container>
				<v-row>
					<v-col md="4" v-for="(setting, index) in this.settings" :key="index">
						<v-card elevation="0" class="p-3 h-100">
							<h2 class="text-primary">{{ setting.name }}</h2>
							<v-list flat>
								<v-list-item-group color="primary">
									<v-list-item v-for="(meta, index2) in setting.items" :key="index2" :to="meta.href">
										<v-list-item-content>
											<v-list-item-title v-text="meta.name"></v-list-item-title>
										</v-list-item-content>
									</v-list-item>
								</v-list-item-group>
							</v-list>
						</v-card>
					</v-col>
				</v-row>
			</v-container>
		</v-main>
	</v-app>
</template>


<script>
import Sidebar from "@/components/Sidebar.vue";
import Topmenubar from "@/components/Topmenubar.vue";
export default {
	components: {
		Sidebar,
		Topmenubar,
	},

	data: () => ({
		breadcrumb: [
			{
				text: "Instellingen",
				disabled: true,
				href: "/settings",
			},
		],
		settings: [
			{
				name: "Algemeen",
				items: [
					{
						name: "Gegevens",
						href: "/settings/general",
					},
					{
						name: "Betalingen",
						href: "/settings/payments",
					},
					{
						name: "Algemene voorwaarden",
						href: "/settings/terms",
					},
					{
						name: "Domeinnaam",
						href: "/settings/domainname",
					},
					{
						name: "Voorkeuren",
						href: "/settings/preferences",
					},
					{
						name: "Widgets",
						href: "/settings/widgets",
					},
				],
			},
			{
				name: "E-mails",
				items: [
					{
						name: "E-mail instellingen",
						href: "/settings/emailsettings",
					},
					{
						name: "E-mail templates",
						href: "/settings/emailtemplates",
					},
					{
						name: "Shortcodes",
						href: "/settings/shortcodes",
					},
				],
			},
			{
				name: "Koppelingen",
				items: [
					{
						name: "Webhooks",
						href: "/settings/webhooks"
					},
					{
						name: "Analytics",
						href: "/settings/analytics",
					},
					{
						name: "Betaalmethodes",
						href: "/settings/paymentmethodes",
					},
				],
			},
			{
				name: "Kyano Orders",
				items: [
					{
						name: "Abonnement beheren",
						href: "",
					},
					{
						name: "Gebruikers",
						href: "",
					}
				],
			},
		],
	}),
};
</script>
<style>
.settings-container {
	padding: 10px;
	background-color: white;
}
.p-3 {
	padding: 15px;
}

.h-100 {
	height: 100%;
}
</style>
