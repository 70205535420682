<template>
	<div>
		<v-radio-group v-model="editingitem.layout" row dense label="Velden weergeven">
			<v-radio v-model="editingitem.layoutVer" label="Verticaal" value="layout-ver"></v-radio>
			<v-radio v-model="editingitem.layoutHor" label="Horizontaal" value="layout-hor"></v-radio>
		</v-radio-group>
	</div>
</template>
<script>
export default {
	props: {
		editingitem: Object
	}
}
</script>
<style scoped>
</style>