<template>
	<router-view :order="order" :submit="submit"></router-view>
</template>

<script>
export default {
	data() {
		return {
			order: {
				id: 1,
				name: "Bestelling 1234",
			},
		};
	},
	methods: {
		submit() {
			//   HIER DAADWERKELIJK SUBMITTEN DOET NU NOG NIKS
		},
	},
};
</script>

<style>
</style>