<template>
	<v-app>
		<Navigations :breadcrumbs="this.breadcrumb" />
		<v-main>
			<v-container>
				<v-card elevation="0" class="p-3 h-100 mt-4 dashboard-spark-card rounded-lg" color="primary">
					<div class="dashboard-omzet">
						<h3>{{ language.orders.dashboard.revenue }}</h3>
						<br />
						<h4 class="dashboard-subtitle">Vandaag</h4>
						<h3>Niet beschikbaar</h3>
						<br />
						<h4 class="dashboard-subtitle">Maand</h4>
						<h3>Niet beschikbaar</h3>
						<br />
						<h4 class="dashboard-subtitle">Jaar</h4>
						<h3>Niet beschikbaar</h3>
					</div>
					<v-sparkline :fill="fill" :gradient="selectedGradient" :line-width="width" :padding="padding" :smooth="radius || false" :value="value" auto-draw></v-sparkline>
				</v-card>

				<!-- <v-row class="py-4">
					<v-col cols="12" md="4">
						<v-card elevation="0" class="pa-3 h-100">
							<h3>Best verkochte producten</h3>
							<v-list flat>
								<v-list-item-group color="primary">
									<v-list-item v-for="(item, i) in itemsbest" :key="i">
										<v-list-item-content>
											<v-list-item-title v-text="item.text"></v-list-item-title>
										</v-list-item-content>
									</v-list-item>
								</v-list-item-group>
							</v-list>
						</v-card>
					</v-col>
					<v-col cols="12" md="4">
						<v-card elevation="0" class="pa-3 h-100">
							<h3>Recente bestellingen</h3>
							<v-list flat>
								<v-list-item-group color="primary">
									<v-list-item v-for="(item, i) in itemsbest" :key="i">
										<v-list-item-content>
											<v-list-item-title v-text="item.text"></v-list-item-title>
										</v-list-item-content>
									</v-list-item>
								</v-list-item-group>
							</v-list>
						</v-card>
					</v-col>
					<v-col cols="12" md="4">
						<v-card elevation="0" class="pa-3 h-100">
							<h3>Aantal abonnementen</h3>
							<v-list flat>
								<v-list-item-group color="primary">
									<v-list-item v-for="(item, i) in itemsbest" :key="i">
										<v-list-item-content>
											<v-list-item-title v-text="item.text"></v-list-item-title>
										</v-list-item-content>
									</v-list-item>
								</v-list-item-group>
							</v-list>
						</v-card>
					</v-col>
				</v-row> -->
			</v-container>
		</v-main>
	</v-app>
</template>
<script>
import { mapGetters } from 'vuex';

import Navigations from "../components/Navigations.vue";

const gradients = [["#005d79"]];

export default {
	components: {
		Navigations
	},

	data: () => ({
		breadcrumb: [
			{
				text: "Dashboard",
				disabled: true,
				href: "/",
			},
		],
		itemsbest: [
			{ text: 'Real-Time' },
			{ text: 'Audience' },
			{ text: 'Conversions' },
			{ text: 'Real-Time' },
			{ text: 'Audience' },
			{ text: 'Conversions' },
		],
		fill: true,
		selectedGradient: gradients[0],
		gradients,
		padding: 0,
		radius: 10,
		value: [0, 2, 5, 9, 5, 10, 3, 5, 8, 7, 9, 12],
		width: 2,
	}),
	computed: {
		...mapGetters({ language: 'getlanguage' })
	}
};
</script>
<style>
.dashboard-subtitle {
	color: var(--secondary-color);
	font-weight: light;
}
.dashboard-spark-card {
	position: relative;
	padding: 100px 0px 0px 0px;
}
.dashboard-omzet {
	padding: 30px 30px 60px 30px;
	left: 0;
	top: 0;
	color: #fff;
	position: absolute;
}
</style>