<template>
	<v-app>
		<Sidebar />
		<Topmenubar :breadcrumbs="this.breadcrumb" />
		<v-main>
			<Subheadertabs :subheadertabs="this.subheadertabs" ref="subheadertabs" />

			<v-container class="p-5" fluid>
				<v-card elevation="0" class="p-5 h-100">
					<h1 class="text-primary">Facturen</h1>
					<p></p>

					<v-row>
						<v-col cols="12">
							<v-data-table :headers="headers" :items="invoices" :items-per-page="25" @click:row="handleclick"> </v-data-table>
						</v-col>
					</v-row>
				</v-card>
			</v-container>
		</v-main>
	</v-app>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Topmenubar from "@/components/Topmenubar.vue";
import Subheadertabs from "@/components/Subheadertabs.vue";
export default {
	components: {
		Sidebar,
		Topmenubar,
		Subheadertabs,
	},
	props: {
		customer: Object,
		submit: Function,
	},
	methods: {
		submitdata() {
			if (this.submit != null) {
				this.submit();
			}
		},
		handleclick() {

		}
	},
	data() {
		return {
			headers: [
				{
					text: "Factuur nr.",
					align: "start",
					sortable: false,
					value: "invoice_nr",
				},

				{ text: "Factuur datum", value: "invoice_date" },
				{ text: "Verloopt op", value: "due_date" },
				{ text: "Betaald op", value: "payed_date" },
				{ text: "Totaal", value: "total_cost" },
				{ text: "Betaalmethode", value: "payment_method" },
				{ text: "Status", value: "status" },
			],

			invoices: [
				{
					invoice_nr: "2021-1234",
					invoice_date: "1-1-2021",
					due_date: "1-1-2021",
					payed_date: "gianni@snelwebcenter.nl",
					total_cost: "€123,00",
					payment_methode: "",
					status: "Betaald",
				},
			],
			breadcrumb: [
				{
					text: "Klanten",
					disabled: false,
					href: "/customers",
				},
				{
					text: `${this.customer.first_name} ${this.customer.last_name}`,
					disabled: false,
					href: `/customer/${this.$route.params.id}/invoices`,
				},
			],
			subheadertabs: [
				{
					text: "Klantgegevens",
					href: `/customer/${this.$route.params.id}`,
				},
				{
					text: "Producten",
					href: `/customer/${this.$route.params.id}/products`,
				},
				{
					text: "Abonnementen",
					href: `/customer/${this.$route.params.id}/subscriptions`,
				},
				{
					text: "Facturen",
					href: `/customer/${this.$route.params.id}/invoices`,
				},
				{
					text: "E-mails",
					href: `/customer/${this.$route.params.id}/emails`,
				},
				// {
				//   text: "Tijdlijn",
				//   href: `/customer/${this.$route.params.id}/timeline`,
				// },
			],
		};
	},
};
</script>

