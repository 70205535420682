<template>
  <router-view :thankyou="thankyou" :order="order" :issuer="issuer" :submit="submit"></router-view>
</template>

<script>
export default {
  data() {
    return {
      order: [
        {
          name: "Kyano licentie - 1 jaar",
          price: 30,
        },
                {
          name: "Kyano licentie - 3 jaar",
          price: 50,
        },
      ],
      issuer: 
        {
          firstname: "Gianni",
          lastname: "Hesseling",
          email: "Gianni@snelwebcenter.nl",
          phonenumber: "0611050013",
          companyname: "Snelwebcenter",
          streetadress: "Moermanskweg 2-22",
          zipcode: "9723HM",
          city: "Groningen",
        },
      thankyou: {
        id: 1,
        medialink: "https://picsum.photos/id/11/500/300",
        mediatype: "video",
        ctaname: "Terug naar onze website",
        ctaurl: "https://www.google.com",
        cta_target_blank: "true",
        Header: "Gefeliciteerd met je bestelling!",
        subheader: "Je bestelling is helemaal geslaagd",
        description:"Je ontvangt ieder moment een bevestiging per mail over deze bestelling",
      },
    };
  },
  methods: {
    submit() {
      //   HIER DAADWERKELIJK SUBMITTEN DOET NU NOG NIKS
    },
  },
};
</script>

<style>
</style>