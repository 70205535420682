<template>
	<div>
		<h4 class="mb-2">Conditionele logica</h4>
		<v-switch v-model="editingitem.useconditions"></v-switch>
		<div v-if="editingitem.useconditions">
			<h4 class="mb-2">Toon dit veld als</h4>
			<v-row v-for="(cg, cgi) in this.editingitem.conditions" :key="cgi" class="mx-2">
				<v-row v-for="con in cg" :key="con.id">
					<v-col cols="3">
						<v-select outlined v-model="con.target" :items="conditionitems" item-text="name" item-value="id"></v-select>
					</v-col>
					<v-col cols="3">
						<v-text-field value="Waarde is gelijk aan" outlined persistent-hint readonly></v-text-field>
					</v-col>
					<v-col cols="4">
						<v-select v-if="conitem(con.target).type === 'radio'" :items="conitem(con.target).itms" item-text="name" item-value="id" outlined persistent-hint v-model="con.value"></v-select>
						<v-text-field v-else outlined persistent-hint v-model="con.value"></v-text-field>
					</v-col>
					<input hidden :value="setcongroup(con, conitem(con.target).group)" />
				</v-row>
			</v-row>
			<v-row>
				<v-col cols="6">
					<v-btn color="primary" right @click="addcondition" elevation="0"> Conditie toevoegen </v-btn>
				</v-col>
			</v-row>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		editingitem: Object,
		conditionitems: Array
	},
	methods: {
		addcondition() {
			if (this.editingitem.conditions === null || this.editingitem.conditions.length === 0) {
				this.editingitem.conditions = [[]];
			}
			this.editingitem.conditions[0].push({
				id: Math.random().toString(36).substr(2, 7),
				target: "",
				value: "",
				group: "",
				type: "",
				subtype: "",
			});
		},
		conitem(id) {
			if(id === "") {
				return "";
			} else {
				return this.conditionitems.filter(itm => itm.id === id)[0];
			}
		},
		setcongroup(con, g) {
			con.group = g;
		}
	},
}
</script>
<style scoped>
</style>