<template>
	<v-app>
		<Sidebar />
		<Topmenubar :breadcrumbs="this.breadcrumb" />
		<v-main>
			<Subheadertabs :subheadertabs="this.subheadertabs" ref="subheadertabs" />

			<v-container class="p-5" fluid>
				<v-card elevation="0" class="p-5 h-100">
					<h1 class="text-primary">Algemeen</h1>
					<p>De basis instellingen van de bedanktpagina.</p>

					<v-row>
						<v-col cols="12">
							<v-text-field v-model="thankyou.pagename" label="Bedanktpagina naam" outlined></v-text-field>
						</v-col>
					</v-row>

					<v-spacer class="my-5"></v-spacer>
					<h1 class="text-primary">Layout</h1>
					<p>Voeg hieronder de content voor op je bedanktpagina.</p>

					<v-row>
						<v-col cols="12">
							<v-text-field v-model="thankyou.Header" label="Titel" hint="Voorbeeld: Gefeliciteerd met je bestelling!" outlined></v-text-field>
							<v-text-field v-model="thankyou.subheader" label="Subtitel" hint="Voorbeeld: Je bestelling is helemaal geslaagd!" outlined></v-text-field>
						</v-col>
					</v-row>

					<v-row>
						<v-col cols="12">
							<v-textarea v-model="thankyou.description" outlined name="input-7-4" label="Beschrijving (optioneel)" value=""></v-textarea>
						</v-col>
					</v-row>

					<v-row>
						<v-col cols="12" md="6">
							<v-btn block color="primary" outlined elevation="0" @click="openCtaMenu">Call to actions instellen</v-btn>
						</v-col>
						<v-col cols="12" md="6">
							<v-btn block color="primary" outlined elevation="0" @click="openSocialsMenu">Social media instellen</v-btn>
						</v-col>
					</v-row>

					<v-row>
						<v-col cols="12">
							<v-select v-model="thankyou.mediatype" :items="items" label="Media" item-text="name" item-value="name" return-object outlined> </v-select>

							<div v-if="`${selectmedia.id}` == '2'">
								<v-file-input v-model="thankyou.fileupload" label="Upload hier je afbeelding" outlined prepend-icon="mdi-image"></v-file-input>
							</div>

							<div v-if="`${selectmedia.id}` == '3'">
								<v-text-field v-model="thankyou.medialink" label="Video URL" hint="Vimeo of youtube link zijn support." outlined></v-text-field>
							</div>
						</v-col>
					</v-row>

					<v-row>
						<v-col cols="12" class="ml-auto text-right">
							<v-btn color="secondary" right @click="submitdatathankyoupage" elevation="0"> Opslaan </v-btn>
						</v-col>
					</v-row>
				</v-card>
			</v-container>
		</v-main>
		<Drawer :direction="'right'" :exist="true" ref="CTADrawer">
			<v-container fluid class="pa-5 pa-md-15">
				<h2>Call-to-action</h2>
				<p>Voeg een knop toe om klanten door te sturen naar bijvoorbeeld een landingspagina.</p>

				<v-row>
					<v-col cols="12"> <v-text-field v-model="thankyou.ctaname" label="Knoptekst" outlined></v-text-field> </v-col
					><v-col cols="12">
						<v-text-field v-model="thankyou.ctaurl" label="URL" outlined></v-text-field>
					</v-col>
				</v-row>

				<v-row>
					<v-col cols="12">
						<v-checkbox v-model="thankyou.cta_target_blank" label="Openen in nieuwe venster"></v-checkbox>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12" class="ml-auto text-right">
						<v-btn color="secondary" right @click="saveCTA" elevation="0"> Opslaan </v-btn>
					</v-col>
				</v-row>
			</v-container>
		</Drawer>

		<Drawer :direction="'right'" :exist="true" ref="SocialsDrawer">
			<v-container fluid class="pa-5 pa-md-15">
				<h2>Social media</h2>
				<p>Plaats op de bedanktpagina linkjes naar jouw Social media pagina’s.</p>
				<v-row>
					<v-col cols="12">
						<v-checkbox v-model="thankyoupage.social_btn_show" label="Toon social media knoppen"></v-checkbox>
					</v-col>
				</v-row>
				<v-divider class="mb-4"></v-divider>
				<v-row>
					<v-col cols="12">
						<v-text-field v-model="thankyoupage.social_title" label="Titel" hint="Volg ons" outlined persistent-hint></v-text-field>
						<v-text-field v-model="thankyoupage.social_secondary_title" label="Ondertitel" hint="Krijg als eerst het nieuws, aanbiedingen etc." outlined persistent-hint></v-text-field>
					</v-col>
				</v-row>
				<v-divider class="mb-4"></v-divider>
				<v-row>
					<v-col cols="12">
						<v-text-field v-model="thankyoupage.social_facebook" label="Facebook" outlined></v-text-field>
						<v-text-field v-model="thankyoupage.social_twitter" label="Twitter" outlined></v-text-field>

						<v-text-field v-model="thankyoupage.social_linkedin" label="Linked-in" outlined></v-text-field>

						<v-text-field v-model="thankyoupage.social_pinterst" label="Pinterest" outlined></v-text-field>

						<v-text-field v-model="thankyoupage.social_instagram" label="Instagram" outlined></v-text-field>
					</v-col>
				</v-row>

				<v-row>
					<v-col cols="12" class="ml-auto text-right">
						<v-btn color="secondary" right @click="savesocials" elevation="0"> Opslaan </v-btn>
					</v-col>
				</v-row>
			</v-container>
		</Drawer>
	</v-app>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Topmenubar from "@/components/Topmenubar.vue";
import Subheadertabs from "@/components/Subheadertabs.vue";
import Drawer from "@/components/Drawer.vue";

export default {
	components: {
		Sidebar,
		Topmenubar,
		Subheadertabs,
		Drawer,
	},

	props: {
		thankyoupage: Object,
		submit: Function,
	},

	methods: {
		openCtaMenu() {
			if (this.$refs.CTADrawer.active) {
				this.$refs.CTADrawer.close();
			} else {
				this.$refs.CTADrawer.open();
			}
		},
		openSocialsMenu() {
			if (this.$refs.SocialsDrawer.active) {
				this.$refs.SocialsDrawer.close();
			} else {
				this.$refs.SocialsDrawer.open();
			}
		},

		submitdatathankyoupage() {
			if (this.submit != null) {
				this.submit();
			}
		},
		saveCTA() {

		},
		savesocials() {

		},
	},

	data() {
		return {
			nameRules: [
				(v) => !!v || "Name is required",
				(v) => /^[a-zA-Z]{2,25}$/.test(v) || "Naam ongeldig",
			],

			fieldRequiredRules: [(v) => !!v || "Dit veld is verplicht"],

			subheadertabs: [
				{
					text: "Algemeen",
					href: `/thankyoupage/${this.$route.params.id}`,
				},
				{
					text: "Geavanceerd",
					href: `/thankyoupage/${this.$route.params.id}/advanced`,
				},
				{
					text: "Weergeven",
					href: "",
				},
			],
			selectmedia: {
				id: '2',
				name: "Afbeelding"
			},


			items: [
				{
					id: 1,
					name: "Geen"
				},
				{
					id: '2',
					name: "Afbeelding"
				},
				{
					id: 3,
					name: "Video"
				}
			],
			breadcrumb: [
				{
					text: "Bedanktpagina's",
					disabled: false,
					href: "/thankyoupages",
				},
				{
					text: this.thankyoupage.name,
					disabled: true,
					href: `/thankyoupage/${this.$route.params.id}`,
				},
			],
			thankyou: {
				id: 1,
				pagename: "Bedanktpaginanaam",
				medialink: "https://picsum.photos/id/11/500/300",
				fileupload: null,
				mediatype: "video",
				ctaname: "Terug naar onze website",
				ctaurl: "https://www.google.com",
				Header: "Gefeliciteerd met je bestelling!",
				subheader: "Je bestelling is helemaal geslaagd",
				description: "Je ontvangt ieder moment een bevestiging per mail over deze bestelling",
				socialmedia: {
					show: "true",
					title: "Volg ons op socialmedia",
					subtitle: "We berichten dagelijks over updates van onze producten",
					facebook: "",
					twitter: "",
					linkedin: "",
					pintrest: "",
					instagram: ""

				}
			},
		};
	},
};
</script>

