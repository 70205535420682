<template>
  <router-view :thankyoupage="thankyoupage" :submit="submit"></router-view>
</template>

<script>
export default {
  data() {
    return {
      thankyoupage: {
        id: 1,
        name: "Bedanktpaginanaam",

        title: "Gefeliciteerd met je bestelling.",
        subtitle: "Je bestelling is helemaal geslaagd",
        discription: "Bedankt voor de aankoop",

        cta_btn_text: "Bestel nu",
        cta_btn_url: "#",
        cta_target_blank: true,


        social_btn_show: true,
        social_title: "Volg ons op social media",
        social_secondary_title: "Doe dit direct",
        social_facebook: "https://facebook.com",
        social_twitter: "http://twitter.nl",
        social_linkedin: "https://linkedin.com",
        social_pinterst: "https://pinterest.com",
        social_instagram: "https://instagram.com",


        media: "video",

        //Geavanceerd
        widget: "<script>",
      },
    };
  },
  methods: {
    submit() {
      //   HIER DAADWERKELIJK SUBMITTEN DOET NU NOG NIKS
    },
  },
};
</script>

<style>
</style>