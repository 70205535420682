<template>
	<v-app>
		<Sidebar />
		<Topmenubar :breadcrumbs="this.breadcrumb" />
		<v-main>
			<Subheadertabs :subheadertabs="this.subheadertabs" ref="subheadertabs" />

			<v-container class="p-5" fluid>
				<v-card elevation="0" class="p-5 h-100">
					<h1 class="text-primary">Algemeen</h1>
					<p></p>

					<v-row>
						<v-col cols="12">
							<v-text-field label="Kortingscodes" outlined></v-text-field>
						</v-col>
					</v-row>

					<v-row>
						<v-col cols="12">
							<v-textarea outlined name="input-7-4" label="Beschrijving (optioneel)" value=""></v-textarea>
						</v-col>
					</v-row>

					<v-row>
						<v-col cols="12">
							<v-select :items="this.coupontypes" label="Kortingstype" outlined> </v-select>
						</v-col>
					</v-row>

					<v-row>
						<v-col cols="12">
							<v-text-field label="Waarde van kortingscode" outlined></v-text-field>
						</v-col>
					</v-row>

					<v-row>
						<v-col cols="12">
							<v-checkbox label="Sta gratis verzending toe" hint="Aanvinken als de kortingscode recht op gratis verzending geeft. Dit moet wel ingesteld zijn bij de checkout pagina waarop het gebruikt kan worden." persistent-hint></v-checkbox>
						</v-col>
					</v-row>

					<v-row>
						<v-col cols="12">
							<v-menu :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
								<template v-slot:activator="{ on, attrs }">
									<v-text-field label="Vervaldatum kortingscode" prepend-icon="mdi-calendar" outlined v-bind="attrs" v-on="on"></v-text-field>
								</template>
								<v-date-picker no-title @input="menu2 = false"></v-date-picker>
							</v-menu>
						</v-col>
					</v-row>

					<v-row>
						<v-col cols="12" class="ml-auto text-right">
							<v-btn color="secondary" right @click="submitdata" elevation="0">Opslaan</v-btn>
						</v-col>
					</v-row>
				</v-card>
			</v-container>
		</v-main>
	</v-app>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Topmenubar from "@/components/Topmenubar.vue";
import Subheadertabs from "@/components/Subheadertabs.vue";

export default {
	components: {
		Sidebar,
		Topmenubar,
		Subheadertabs,
	},
	props: {
		subscription: Object,
		submit: Function,
	},

	methods: {
		submitdata() {
			if (this.submit != null) {
				this.submit();
			}
		},
	},

	data() {
		return {
			nameRules: [
				(v) => !!v || "Name is required",
				(v) => /^[a-zA-Z]{2,25}$/.test(v) || "Naam ongeldig",
			],

			subheadertabs: [
				{
					text: "Algemeen",
					href: `/subscription/${this.$route.params.id}`,
				},
			],

			breadcrumb: [
				{
					text: "Abonnementen",
					disabled: false,
					href: "/subscriptions",
				},
				{
					text: this.subscription.name,
					disabled: true,
					href: "#",
				},
			],

			coupontypes: [

			],
		};
	},
};
</script>

