<template>
	<div>
		<h4 class="mb-2">Algemeen</h4>
		<!-- Field title -->
		<FieldTitle :editingitem="editingitem" />

		<!-- Custom component fields-->
		<v-select :items="contenttype" item-text="dis" item-value="val" label="Content soort" outlined v-model="editingitem.contenttype"></v-select>
		<v-textarea v-model="editingitem.contentvalue" outlined name="Tekst" label="Tekst" :value="value" persistent-hint hint="Deze content zal in het formulier getoond worden als titel of informatie."></v-textarea>
		<!--// END Custom component fields-->

		<!-- Conditions -->
		<Conditions :editingitem="editingitem" :conditionitems="conditionitems" />
		<!-- Advanced fields; ID and class-->
		<Advanced :editingitem="editingitem" />
	</div>
</template>
<script>

import FieldTitle from "../../form-components/FieldTitle.vue";
import Conditions from "../../form-components/Conditions.vue";
import Advanced from "../../form-components/Advanced.vue";



export default {
	components: {
		Conditions,
		Advanced,
		FieldTitle,
	},
	props: {
		editingitem: Object,
		conditionitems: Array
	},
	data() {
		return {
			contenttype: [
				{
					val: "h1",
					dis: "h1",
				},
				{
					val: "h2",
					dis: "h2",
				},
				{
					val: "h3",
					dis: "h3",
				},
				{
					val: "h4",
					dis: "h4",
				},
				{
					val: "h5",
					dis: "h5",
				},
				{
					val: "h6",
					dis: "h6",
				},
				{
					val: "p",
					dis: "p",
				},
			],
		}
	},
}
</script>
