<template>
  <v-app-bar class="sub-top-bar" color="#fff" elevation="0">
    <v-tabs v-model="tab" color="#011a62">
      <v-tabs-slider color="#34c759"></v-tabs-slider>
      <v-tab v-for="(item, index) in subheadertabs" :key="index" :to="item.href">
        {{ item.text }}
      </v-tab>
    </v-tabs>
  </v-app-bar>
</template>


<script>
export default {
  props: {
    subheadertabs: Array,
    updateactivetab: Function
  },
  data() {
    return {
      tab: "#",
    };
  },
  watch: {
    tab: function (n, o) {
      if (this.updateactivetab != null) { 
        this.updateactivetab(n, o)
      }
    }
  }
};
</script>
