<template>
  <v-app>
    <v-main color="white" class="bg-white">
      <v-container>
        <v-row class="py-15 align-center h-100">
          <v-col cols="12" lg="4" md="6" sm="8" class="text-center m-auto">
            <v-card
              elevation="0"
              class="pa-10 h-100 mt-4 dashboard-spark-card rounded-lg"
              color="white align-center"
            >
              <v-img
                class="m-auto mb-3"
                max-width="165"
                max-height="31"
                src="../assets/kyano-account-logo-dark.svg"
              
              ></v-img>

              <h1 class="m-auto mb-3 title">Nieuw wachtwoord</h1>
              <p>Voer een nieuw wachtwoord in voor je Kyano account.</p>
               <v-text-field
            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, rules.min]"
            :type="show2 ? 'text' : 'password'"
            name="input-10-2"
            label="Wachtwoord"
            hint="Minimaal 8 tekens"
            value="wqfasds"
            class="input-group--focused"
            @click:append="show2 = !show2"
            outlined
          ></v-text-field>
          <v-text-field
            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, rules.min]"
            :type="show2 ? 'text' : 'password'"
            name="input-10-2"
            label="Herhaal wachtwoord"
            hint="Minimaal 8 tekens"
            value="wqfasds"
            class="input-group--focused"
            @click:append="show2 = !show2"
            outlined
          ></v-text-field>

                                  
              <v-row>
                <v-col cols="6"> <v-btn
                elevation="0"
                large
                 block
                to="/login"
                :submit="submit"
                color="white"
                >Terug naar inloggen</v-btn
              ></v-col>
                 <v-col cols="6"> <v-btn
                elevation="0"
                large
                block
                :submit="submit"
                color="secondary"
                >Wachtwoord aanvragen</v-btn
              ></v-col>
              </v-row>
             
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
 export default {
    data () {
      return {       
        show2: false,        
        password: 'Wachtwoord',
        rules: {
          required: value => !!value || 'Verplicht.',
          min: v => v.length >= 8 || 'Min 8 tekens',
          emailMatch: () => (`The email and password you entered don't match`),
        },
      }
    },
  }
</script>
<style>
</style>