<template>
	<div>						
		<h4>Geavanceerd</h4>
		<v-row>
			<!-- <v-col cols="6">
				<v-text-field label="ID" outlined persistent-hint v-model="editingitem.fieldid"></v-text-field>
			</v-col> -->
			<v-col cols="6">
				<v-text-field label="Class" outlined persistent-hint v-model="editingitem.fielclass"></v-text-field>
			</v-col>
		</v-row>
	</div>
</template>
<script>
export default {
	props: {
		editingitem: Object
	}
}
</script>