<template>
	<v-app>
		<Sidebar />
		<Topmenubar :breadcrumbs="this.breadcrumb" />
		<v-main>
			<Subheadertabs :subheadertabs="this.subheadertabs" ref="subheadertabs" />

			<v-container class="p-5" fluid>
				<v-card elevation="0" class="p-5 h-100">
					<h1 class="text-primary">Algemeen</h1>
					<p></p>

					<v-row>
						<v-col cols="12">
							<v-text-field v-model="checkout.name" :rules="rules.name" label="Naam" outlined placeholder="Naam van de checkout" persistent-hint></v-text-field>
						</v-col>
					</v-row>

					<v-row>
						<v-col cols="12" class="d-flex">
							<v-text-field v-model="checkout.pageurl" label="URL" prefix="https://snelwebcenter.orders.app/" placeholder="" outlined></v-text-field>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12">
							<v-select :items="this.allOwnedproducts" label="Selecteer een product" persistent-hint placeholder="Selecteer een product" outlined></v-select>
						</v-col>
					</v-row>

					<v-row>
						<v-col cols="12">
							<v-text-field label="Eigen URL" outlined></v-text-field>
							<v-text-field label="Bedanktpagina URL" outlined></v-text-field>
						</v-col>
					</v-row>

					<v-row>
						<v-col cols="12">
							<v-checkbox label="Deactiveren" hint="Deactiveer de checkout tijdelijk of voor onbepaalde tijd." persistent-hint></v-checkbox>
						</v-col>
					</v-row>

					<v-row>
						<v-col cols="12" class="ml-auto text-right">
							<v-btn color="secondary" right @click="submitdata" elevation="0"> Opslaan </v-btn>
						</v-col>
					</v-row>
				</v-card>
			</v-container>
		</v-main>
	</v-app>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Topmenubar from "@/components/Topmenubar.vue";
import Subheadertabs from "@/components/Subheadertabs.vue";
import config from "@/config";

export default {
	components: {
		Sidebar,
		Topmenubar,
		Subheadertabs,
	},

	props: {
		checkout: Object,
		submit: Function,
	},

	methods: {
		submitdata() {
			if (this.submit != null) {
				this.submit();
			}
		},
	},

	data() {
		return {
			rules: {
				user: [
					value => !!value || 'Required.',
					value => (value && value.length >= 2) || 'Minimum 2 characters',
					value => (value && !(value.length > 50)) || 'Maximum 24 characters',
					value => (config.regex.user.test(value)) || 'Invalid characters',
				],
			},
			allOwnedproducts: [
				"Product1",
				"Product2"
			],
			subheadertabs: [
				{
					text: "Algemeen",
					href: `/checkout/${this.$route.params.id}`,
				},
				{
					text: "Geavanceerd",
					href: `/checkout/${this.$route.params.id}/advanced`,
				},

				{
					text: "One-click upsells",
					href: `/checkout/${this.$route.params.id}/upsells`,
				},
				{
					text: "Layouts",
					href: `/checkout/${this.$route.params.id}/layout`,
				},
				{
					text: "Weergeven",
					href: `/`,
				},
			],

			breadcrumb: [
				{
					text: "Checkouts",
					disabled: false,
					href: "/checkouts",
				},
				{
					text: this.checkout.name,
					disabled: true,
					href: `/checkout/${this.$route.params.name}`,
				},
			],
		};
	},
};
</script>

