<template>
  <v-app>
    <Sidebar />
    <Topmenubar :breadcrumbs="this.breadcrumb" />

    <v-main>
      <Subheadertabs :subheadertabs="this.subheadertabs" />

      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Topmenubar from "@/components/Topmenubar.vue";
import Subheadertabs from "@/components/Subheadertabs.vue";
export default {
  components: {
    Sidebar,
    Topmenubar,
    Subheadertabs,
  },

  data: () => ({
    breadcrumb: [
      {
        text: "Instellingen",
        disabled: false,
        href: "/settings",
      },
       {
          text: "Koppelingen",
          disabled: true,
          href: "/settings",
        },
      {
        text: "Webhooks",
        disabled: true,
        href: "/settings/webhooks",
      },
    ],
    subheadertabs: [
        {
            text: "Webhooks",
            href: "/settings/webhooks"
        },
         {
            text: "Analytics",
            href: "/settings/analytics"
        },
         {
            text: "Betaalmethodes",
            href: "/settings/paymentmethodes"
        }
    ],
  }),
};
</script>

