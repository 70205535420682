<template>
	<v-app>
		<Sidebar />
		<Topmenubar :breadcrumbs="this.breadcrumb" />
		<v-main>
			<Subheadertabs :subheadertabs="this.subheadertabs" ref="subheadertabs" />

			<v-container class="p-5" fluid>
				<v-card elevation="0" class="p-5 h-100">
					<h1 class="text-primary">Geavanceerd</h1>
					<p>Plaats een script of widget op de bedanktpagina.</p>

					<v-row>
						<v-col cols="12">
							<v-textarea outlined v-model="thankyoupage.widget" label="Javascript widget (optioneel)" placeholder="<SCRIPT></SCRIPT>"></v-textarea>
						</v-col>
					</v-row>

					<v-row>
						<v-col cols="12" class="ml-auto text-right">
							<v-btn color="secondary" right @click="submitdatathankyoupage" elevation="0"> Opslaan </v-btn>
						</v-col>
					</v-row>
				</v-card>
			</v-container>
		</v-main>
	</v-app>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Topmenubar from "@/components/Topmenubar.vue";
import Subheadertabs from "@/components/Subheadertabs.vue";

export default {
	components: {
		Sidebar,
		Topmenubar,
		Subheadertabs,
	},

	props: {
		thankyoupage: Object,
		submit: Function,
	},

	methods: {
		submitdatathankyoupage() {
			if (this.submit != null) {
				this.submit();
			}
		},
	},

	data() {
		return {
			nameRules: [
				(v) => !!v || "Name is required",
				(v) => /^[a-zA-Z]{2,25}$/.test(v) || "Naam ongeldig",
			],

			subheadertabs: [
				{
					text: "Algemeen",
					href: `/thankyoupage/${this.$route.params.id}`,
				},
				{
					text: "Geavanceerd",
					href: `/thankyoupage/${this.$route.params.id}/advanced`,
				},
				{
					text: "Weergeven",
					href: "",
				},
			],

			breadcrumb: [
				{
					text: "Bedanktpagina's",
					disabled: false,
					href: "/thankyoupages",
				},
				{
					text: this.thankyoupage.name,
					disabled: true,
					href: `/thankyoupage/${this.$route.params.id}/advanced`,
				},
			],
		};
	},
};
</script>

