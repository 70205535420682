<template>
	<router-view :checkout="checkout" :submit="submit"></router-view>
</template>

<script>
export default {
	data() {
		return {
			checkout: {
				id: 1,
				name: "Kyano licentie",
				pageurl: "afrekenen",
				product: " ",
			},
		};
	},
	methods: {
		submit() {
			//   HIER DAADWERKELIJK SUBMITTEN DOET NU NOG NIKS
		},
	},
};
</script>

<style>
</style>