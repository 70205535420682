<template>
	<v-app>
		<Sidebar />
		<Topmenubar :breadcrumbs="this.breadcrumb" />

		<v-main>
			<Subheadertabs :subheadertabs="this.subheadertabs" ref="subheadertabs" />

			<v-container class="p-5" fluid>
				<v-card elevation="0" class="p-5 h-100">
					<h1 class="text-primary">Algemene voorwaarden</h1>
					<p>Upload hier je algemene voorwaarden die je wilt hanteren bij de checkouts.</p>

					<v-row>
						<v-col cols="12">
							<v-file-input v-model="setting.termsdocument" label="Upload hier je bestand" outlined prepend-icon="mdi-image"></v-file-input>
						</v-col>
					</v-row>

					<v-row>
						<v-col cols="12" class="ml-auto text-right">
							<v-btn color="secondary" right @click="submit" elevation="0"> Opslaan </v-btn>
						</v-col>
					</v-row>
				</v-card>
			</v-container>
		</v-main>
	</v-app>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Topmenubar from "@/components/Topmenubar.vue";
import Subheadertabs from "@/components/Subheadertabs.vue";
export default {
	components: {
		Sidebar,
		Topmenubar,
		Subheadertabs,
	},

	props: {
		setting: Object,
		submit: Function,
	},

	data: () => ({
		breadcrumb: [
			{
				text: "Instellingen",
				disabled: false,
				href: "/settings",
			},
			{
				text: "Algemene voorwaarden",
				disabled: true,
				href: "/settings/terms",
			},
		],
		subheadertabs: [
			{
				text: "Gegevens",
				href: "/settings/general",
			},
			{
				text: "Betalingen",
				href: "/settings/payments",
			},
			{
				text: "Algemene voorwaarden",
				href: "/settings/terms",
			},
			{
				text: "Domeinnaam",
				href: "/settings/domainname",
			},
			{
				text: "Voorkeuren",
				href: "/settings/preferences",
			},
			{
				text: "Widgets",
				href: "/settings/widgets",
			},
		],
	}),
};
</script>

