<template>
	<v-app>
		<Sidebar />
		<Topmenubar :breadcrumbs="this.breadcrumb" />
		<v-main>
			<Subheadertabs :subheadertabs="this.subheadertabs" ref="subheadertabs" />

			<v-container class="p-5" fluid>
				<v-card elevation="0" class="p-5 h-100">
					<h1 class="text-primary">E-mail templates</h1>
					<p>Pas hier de email templates aan.</p>

					<v-row dense>
						<v-col cols="10">
							<v-select v-model="selectMailTemplate" :items="items" prefix="Mail template:" item-text="template" item-value="name" label="Selecteer een email template" return-object outlined> </v-select>
						</v-col>

						<v-btn color="secondary" class="mt-1" right x-large @click="submit" elevation="0"> Opslaan </v-btn>
					</v-row>
				</v-card>
				<v-row class="pt-5">
					<v-col cols="2"></v-col>
					<v-col cols="8">
						<v-card color="systemwhite" elevation="0" class="pa-5 mb-3 rounded-lg">
							<p v-html="`${selectMailTemplate.discription}`"></p>
							<v-divider class="py-4"></v-divider>

							<!-- Order bevestiging mailtemplate -->
							<div v-if="`${selectMailTemplate.name}` === 'mailtemplate1'">
								<v-text-field v-model="setting.mt_orderconfirmation_subject" label="Onderwerp" hint="Bevestiging van bestelling #[order_id]" outlined persistent-hint></v-text-field>

								<v-textarea v-model="setting.mt_orderconfirmation_discription" outlined label="Omschrijving"></v-textarea>
							</div>
							<!-- //Order bevestiging mailtemplate -->
							<!--Betaallink mailtemplate -->
							<div v-if="`${selectMailTemplate.name}` === 'mailtemplate2'">
								<v-text-field v-model="setting.mt_paymentlink_subject" label="Onderwerp" hint="Betaallink van checkout #[order_id]" outlined persistent-hint></v-text-field>

								<v-textarea v-model="setting.mt_paymentlink_discription" outlined label="Omschrijving"></v-textarea>
							</div>
							<!-- //Betaallink mailtemplate -->
							<!-- Checkout notificatie mailtemplate -->
							<div v-if="`${selectMailTemplate.name}` === 'mailtemplate3'">
								<v-text-field v-model="setting.mt_checkoutnotice_subject" label="Onderwerp" hint="Nieuwe checkout #[order_id]" outlined persistent-hint></v-text-field>

								<v-textarea v-model="setting.mt_checkoutnotice_discription" outlined label="Omschrijving"></v-textarea>
							</div>
							<!-- //Checkout notificatie mailtemplate -->
							<!-- Factuur toesturen mailtemplate -->
							<div v-if="`${selectMailTemplate.name}` === 'mailtemplate4'">
								<v-text-field v-model="setting.mt_sendinvoice_subject" label="Onderwerp" hint="Factuur #[invoice_nr] van [store_name]" outlined persistent-hint></v-text-field>

								<v-textarea v-model="setting.mt_sendinvoice_discription" outlined label="Omschrijving"></v-textarea>
							</div>
							<!-- //factuur toesturen mailtemplate -->

							<!-- 1e betalingsherinnering mailtemplate -->
							<div v-if="`${selectMailTemplate.name}` === 'mailtemplate5'">
								<v-text-field v-model="setting.mt_paymentreminder1_subject" label="Onderwerp" hint="Betalingsherinnering voor factuur #[invoice_no]" outlined persistent-hint></v-text-field>

								<v-textarea v-model="setting.mt_paymentreminder1_discription" outlined label="Omschrijving"></v-textarea>
							</div>
							<!-- //1e betalingsherinnering mailtemplate -->

							<!-- 2e betalingsherinnering mailtemplate -->
							<div v-if="`${selectMailTemplate.name}` === 'mailtemplate6'">
								<v-text-field v-model="setting.mt_paymentreminder2_subject" label="Onderwerp" hint="2e betalingsherinnering voor factuur #[invoice_no]" outlined persistent-hint></v-text-field>

								<v-textarea v-model="setting.mt_paymentreminder2_discription" outlined label="Omschrijving"></v-textarea>
							</div>
							<!-- //2e betalingsherinnering mailtemplate -->

							<!-- 3e betalingsherinnering mailtemplate -->
							<div v-if="`${selectMailTemplate.name}` === 'mailtemplate7'">
								<v-text-field v-model="setting.mt_paymentreminder3_subject" label="Onderwerp" hint="3e betalingsherinnering voor factuur #[invoice_no]" outlined persistent-hint></v-text-field>

								<v-textarea v-model="setting.mt_paymentreminder3_discription" outlined label="Omschrijving"></v-textarea>
							</div>
							<!-- //3e betalingsherinnering mailtemplate -->

							<span><strong>Ordernummer: </strong><span>#123</span></span>
							<div class="pa-4"></div>
							<v-row>
								<v-col cols="5">
									<strong>Artikel</strong>
								</v-col>
								<v-col cols="2">
									<strong>Aantal</strong>
								</v-col>
								<v-col cols="2">
									<strong>BTW</strong>
								</v-col>
								<v-col cols="3" class="text-right">
									<strong>Prijs</strong>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="5">
									<p>Productnaam</p>
								</v-col>
								<v-col cols="2">
									<p>1</p>
								</v-col>
								<v-col cols="2">
									<p>21%</p>
								</v-col>
								<v-col cols="3" class="text-right">
									<p>€ 100,00</p>
								</v-col>
							</v-row>
							<v-divider></v-divider>
							<v-row>
								<v-col cols="5"> </v-col>
								<v-col cols="2"> </v-col>
								<v-col cols="2">
									<strong>Subtotaal</strong>
									<p>BTW 21%</p>
								</v-col>
								<v-col cols="3" class="text-right">
									<strong>€ 100,00</strong>
									<p>€ 21,00</p>
								</v-col>
							</v-row>
							<v-divider></v-divider>
							<v-row>
								<v-col cols="5"> </v-col>
								<v-col cols="2"> </v-col>
								<v-col cols="2">
									<strong>Totaal</strong>
								</v-col>
								<v-col cols="3" class="text-right">
									<strong>€ 121,00</strong>
								</v-col>
							</v-row>
							<div class="pa-4"></div>

							<!--Orderbevestiging-->
							<div v-if="`${selectMailTemplate.name}` === 'mailtemplate1'">
								<v-textarea v-model="setting.mt_orderconfirmation_signature" outlined label="Handtekening"></v-textarea>
							</div>
							<!--//Orderbevestiging-->

							<!-- Betaallink template-->
							<div v-if="`${selectMailTemplate.name}` === 'mailtemplate2'">
								<v-textarea v-model="setting.mt_paymentlink_signature" outlined label="Handtekening"></v-textarea>
							</div>
							<!--// Betaallink template-->

							<!-- Checkout notificatie template-->
							<div v-if="`${selectMailTemplate.name}` === 'mailtemplate3'">
								<v-textarea v-model="setting.mt_checkoutnotice_signature" outlined label="Handtekening"></v-textarea>
							</div>
							<!--// Checkout notificatie template-->

							<!-- Checkout notificatie template-->
							<div v-if="`${selectMailTemplate.name}` === 'mailtemplate4'">
								<v-textarea v-model="setting.mt_sendinvoice_signature" outlined label="Handtekening"></v-textarea>
							</div>
							<!--// Checkout notificatie template-->

							<!-- Checkout notificatie template-->
							<div v-if="`${selectMailTemplate.name}` === 'mailtemplate5'">
								<v-textarea v-model="setting.mt_paymentreminder1_signature" outlined label="Handtekening"></v-textarea>
							</div>
							<!--// Checkout notificatie template-->

							<!-- Checkout notificatie template-->
							<div v-if="`${selectMailTemplate.name}` === 'mailtemplate6'">
								<v-textarea v-model="setting.mt_paymentreminder2_signature" outlined label="Handtekening"></v-textarea>
							</div>
							<!--// Checkout notificatie template-->

							<!-- Checkout notificatie template-->
							<div v-if="`${selectMailTemplate.name}` === 'mailtemplate7'">
								<v-textarea v-model="setting.mt_paymentreminder3_signature" outlined label="Handtekening"></v-textarea>
							</div>
							<!--// Checkout notificatie template-->

							<div class="pa-4"></div>
							<p class="text-center">
								<span v-html="`${setting.shopname}`"></span> - <span v-html="`${setting.adres}`"></span> - <span v-html="`${setting.postcode}`"></span> -
								<span v-html="`${setting.location_state}`"></span>
							</p>

							<v-row>
								<v-col cols="12" class="ml-auto text-right">
									<v-btn color="secondary" right @click="submitdata" elevation="0"> Opslaan </v-btn>
								</v-col>
							</v-row>
						</v-card>
					</v-col>
				</v-row>
			</v-container>
		</v-main>
	</v-app>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Topmenubar from "@/components/Topmenubar.vue";
import Subheadertabs from "@/components/Subheadertabs.vue";
export default {
	components: {
		Sidebar,
		Topmenubar,
		Subheadertabs,
	},

	props: {
		setting: Object,
		submit: Function,
	},

	data: () => ({
		breadcrumb: [
			{
				text: "Instellingen",
				disabled: false,
				href: "/settings",
			},
			{
				text: "E-mail templates",
				disabled: true,
				href: "/settings/emailtemplates",
			},
		],
		subheadertabs: [
			{
				text: "Email instellingen",
				href: "/settings/emailsettings/",
			},
			{
				text: "Email templates",
				href: "/settings/emailtemplates",
			},
			{
				text: "Shortcodes",
				href: "/settings/shortcodes",
			},
		],

		selectMailTemplate: {
			template: "Orderbevesting",
			name: "mailtemplate1",
			discription:
				"Deze mailtemplate wordt getoond bij een nieuwe bestelling naar de klant.",
		},
		items: [
			{
				template: "Orderbevesting",
				name: "mailtemplate1",
				discription:
					"Deze mailtemplate wordt getoond bij een nieuwe bestelling naar de klant.",
			},
			{
				template: "Betaallink",
				name: "mailtemplate2",
				discription:
					"Deze mailtemplate wordt getoond zodra een betaallink verstuurd wordt.",
			},
			{
				template: "Checkout notificatie",
				name: "mailtemplate3",
				discription:
					"ADMIN MAIL - Deze mail ontvang je als admin zodra er een nieuwe checkout is.",
			},
			{
				template: "Factuur toesturen",
				name: "mailtemplate4",
				discription: "Verstuur nogmaals een factuur naar de klant.",
			},
			{
				template: "1e betalingsherinnering",
				name: "mailtemplate5",
				discription: "De 1e betaal herinnering naar de klant.",
			},
			{
				template: "2e betalingsherinnering",
				name: "mailtemplate6",
				discription: "De 2e betaal herinnering naar de klant.",
			},
			{
				template: "3e betalingsherinnering",
				name: "mailtemplate7",
				discription: "De 2e betaal herinnering naar de klant.",
			},
		],
	}),
	methods: {
		submitdata() {
			if (this.submit != null) {
				this.submit();
			}
		}
	}
};
</script>

