<template>
	<v-app>
		<Sidebar />
		<Topmenubar :breadcrumbs="this.breadcrumb" />
		<v-main>
			<Subheadertabs :subheadertabs="this.subheadertabs" ref="subheadertabs" />

			<v-container class="p-5" fluid>
				<v-card elevation="0" class="p-5 h-100">
					<h1 class="text-primary">Gebruiksbeperking</h1>
					<p></p>

					<v-row>
						<v-col cols="6">
							<v-text-field v-model="coupon.minspend" label="Minimale besteding" hint="Hier kun je de minimale subtotaal plaatsen dat gebruikt moet worden voor deze code." outlined type="number" persistent-hint></v-text-field>
						</v-col>
						<v-col cols="6">
							<v-text-field v-model="coupon.maxspend" label="Maximale besteding" hint="Hier kan je het maximaal toegestane subtotaal in stellen voor het gebruik van deze kortingsbon." outlined type="number" persistent-hint></v-text-field>
						</v-col>
					</v-row>

					<v-row>
						<v-col cols="12">
							<v-checkbox v-model="coupon.SingleUseCheckbox" label="Enkele individueel gebruik" hint="Vink aan als deze kortingsbon niet in combinatie met andere kortingsbonnen gebruikt kan worden." persistent-hint></v-checkbox>
						</v-col>
					</v-row>

					<v-row>
						<v-col cols="12">
							<v-select v-model="coupon.products" :items="products" label="Producten" multiple chips outlined placeholder="Zoek naar een product…." hint="Producten waarop de kortingsbon wordt toegepast of die in de winkelmand moeten worden geplaatst om de ‘vaste winkelmandkorting’ toe te passen." persistent-hint></v-select>
						</v-col>
					</v-row>

					<v-row>
						<v-col cols="12">
							<v-select v-model="coupon.productsdisallowed" :items="products" label="Uitgesloten producten" multiple chips outlined placeholder="Zoek naar een product…." hint="Producten waarop de kortingsbon niet wordt toegepast of die niet in de winkelmand kunnen worden geplaatst om de ‘vaste winkelmandkorting’ toe te passen." persistent-hint></v-select>
						</v-col>
					</v-row>

					<v-row>
						<v-col cols="12">
							<v-select v-model="coupon.productcats" :items="productcats" label="Productcategorieën" multiple chips outlined placeholder="Elke categorie" hint="Productcategorieën waarop de kortingsbon wordt toegepast of die in de winkelmand moeten worden geplaatst om de ‘vaste winkelmandkorting’ toe te passen." persistent-hint></v-select>
						</v-col>
					</v-row>

					<v-row>
						<v-col cols="12">
							<v-select v-model="coupon.productcatsdisallowed" :items="productcats" label="Uitgesloten categorieën" multiple chips outlined placeholder="Geen categorieën" hint="Productcategorieën waarop de kortingsbon niet wordt toegepast of die niet in de winkelmand kunnen worden geplaatst om de ‘vaste winkelmandkorting’ toe te passen." persistent-hint></v-select>
						</v-col>
					</v-row>

					<v-row>
						<v-col cols="12" class="ml-auto text-right">
							<v-btn color="secondary" right @click="submit" elevation="0"> Opslaan </v-btn>
						</v-col>
					</v-row>
				</v-card>
			</v-container>
		</v-main>
	</v-app>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Topmenubar from "@/components/Topmenubar.vue";
import Subheadertabs from "@/components/Subheadertabs.vue";

export default {
	components: {
		Sidebar,
		Topmenubar,
		Subheadertabs,
	},
	props: {
		coupon: Object,
		submit: Function,
	},

	data() {
		return {
			subheadertabs: [
				{
					text: "Algemeen",
					href: `/coupon/${this.$route.params.id}`,
				},
				{
					text: "Gebruiksbeperking",
					href: `/coupon/${this.$route.params.id}/usage-restriction`,
				},
				{
					text: "Gebruikslimiet",
					href: `/coupon/${this.$route.params.id}/usage-limit`,
				},
			],

			breadcrumb: [
				{
					text: "Kortingscode",
					disabled: false,
					href: "/coupons",
				},
				{
					text: `${this.$route.params.name}`,
					disabled: true,
					href: `/coupon/${this.$route.params.id}/usage-restriction`,
				},
			],

			//Ultra fake data

			productcats: ["licenties", "paketten"],
			products: ["product 1", "product 2"],

		};
	},
};
</script>

