<template>
	<v-app>
		<Sidebar />
		<Topmenubar :breadcrumbs="this.breadcrumb" />
		<v-main>
			<Subheadertabs :subheadertabs="this.subheadertabs" ref="subheadertabs" />

			<v-container class="p-5" fluid>
				<v-card elevation="0" class="p-5 h-100">
					<h1 class="text-primary">Gebruikslimiet</h1>
					<p></p>
					<v-row>
						<v-col cols="12">
							<v-text-field v-model="coupon.userlimitpercoupon" label="Gebruikerslimiet per kortingscode" hint="Het aantal keer dat deze kortingscode gebruikt mag worden voordat hij vervalt." placeholder="Onbeperkt gebruik" outlined persistent-hint></v-text-field>
						</v-col>
					</v-row>

					<v-row>
						<v-col cols="12">
							<v-text-field v-model="coupon.userlimitpercustomer" label="Gebruikslimiet per klant" hint="Aantal keer dat de kortingscode gebruikt mag worden door een individuele gebruiker. Gebruikt factuur e-mailadres voor de gasten en gebruikers-ID voor de ingelogde gebruikers." placeholder="Onbeperkt gebruik" outlined persistent-hint></v-text-field>
						</v-col>
					</v-row>

					<v-row>
						<v-col cols="12" class="ml-auto text-right">
							<v-btn color="secondary" right @click="submit" elevation="0"> Opslaan </v-btn>
						</v-col>
					</v-row>
				</v-card>
			</v-container>
		</v-main>
	</v-app>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Topmenubar from "@/components/Topmenubar.vue";
import Subheadertabs from "@/components/Subheadertabs.vue";

export default {
	components: {
		Sidebar,
		Topmenubar,
		Subheadertabs,
	},

	props: {
		coupon: Object,
		submit: Function
	},



	data() {
		return {
			subheadertabs: [
				{
					text: "Algemeen",
					href: `/coupon/${this.$route.params.id}`,
				},
				{
					text: "Gebruiksbeperking",
					href: `/coupon/${this.$route.params.id}/usage-restriction`,
				},
				{
					text: "Gebruikslimiet",
					href: `/coupon/${this.$route.params.id}/usage-limit`,
				},
			],

			breadcrumb: [
				{
					text: "Kortingscode",
					disabled: false,
					href: "/coupons",
				},
				{
					text: `${this.$route.params.name}`,
					disabled: true,
					href: `/coupon/${this.$route.params.id}/usage-limit`,
				},
			],
		};
	},
};
</script>

