<template>
  <router-view :customer="customer" :submit="submit"></router-view>
</template>

<script>
export default {
  data() {
    return {
      customer: {
        id: 1,
        companyname: "Snelwebcenter",
        first_name: "Gianni",
        last_name: "Hesseling",
        emailadres: "gianni@snelwebcenter.nl",
        phonenumber: "0612345678",
        adres: "Moermanskweg 2",
        adres2: "nvt",
        postcode: "9723HM",
        location_state: "Groningen",
        province: "Grunn",
        country: "Nederland",

        checkouts: [
          {
            id: 1,
            name: "checkoutnaam",
            lastorder: "",
            email: "",
            subscription:"",
            orders:"",
            totalspend:"",
            country:"",
            location:"",
          },
        ],
      },
    };
  },
  methods: {
    submit() {
      //   HIER DAADWERKELIJK SUBMITTEN DOET NU NOG NIKS
    },
  },
};
</script>

<style>
</style>