<template>
  <router-view :coupon="coupon" :submit="submit"></router-view>
</template>

<script>
export default {
  data() {
    return {
      coupon: {
        id: 1,
        name: "KORTINGSCODE10",
        discription: "dit is de omschrijving.",
        coupontype: ["Vast winkelmand korting"], // Dannycheck
        value: 10,
        freeshippingallowed: false,
        computedDateFormatted: "", // Dannycheck

        //Coupon Usage Limit
        userlimitpercoupon: 1,
        userlimitpercustomer: 1,

        //Coupon Usage Restriction
        minspend: 10,
        maxspend: 10,
        SingleUseCheckbox: true,
        products: ["product 1", "product 2"],
        productsdisallowed: ["product 1"],
        productcats: ["licenties", "paketten"],
        productcatsdisallowed: ["licenties"],
      },
    };
  },
  methods: {
    submit() {
      //   HIER DAADWERKELIJK SUBMITTEN DOET NU NOG NIKS
    },
  },
};
</script>

<style>
</style>