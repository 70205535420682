<template>
	<v-app>
		<Sidebar />
		<Topmenubar :breadcrumbs="this.breadcrumb" />
		<v-main>
			<Subheadertabs :subheadertabs="this.subheadertabs" ref="subheadertabs" />

			<v-container class="p-5" fluid>
				<v-card elevation="0" class="p-5 h-100">
					<h1 class="text-primary">Velden tonen</h1>
					<p>Vraag op de checkoutpagina naar de volgende informatie.</p>
					<v-row>
						<v-col cols="12" md="4">
							<v-card outlined elevation="0" class="pa-5 h-100">
								<h4>Adres veld</h4>
								<v-row>
									<v-col cols="6"> <v-checkbox v-model="checkout.adres" :label="'Toon veld'" hint="Toon dit veld"></v-checkbox></v-col>
									<v-col cols="6"> <v-checkbox v-model="checkout.adresrequired" :label="'Verplicht'" hint="Selecteer indien dit veld verplicht is."></v-checkbox></v-col>
								</v-row>
							</v-card>
						</v-col>
						<v-col cols="12" md="4">
							<v-card outlined elevation="0" class="pa-5 h-100">
								<h4>Telefoonnummer veld</h4>
								<v-row>
									<v-col cols="6"> <v-checkbox v-model="checkout.phone" :label="'Toon veld'" hint="Toon dit veld"></v-checkbox></v-col>
									<v-col cols="6"> <v-checkbox v-model="checkout.phonerequired" :label="'Verplicht'" hint="Selecteer indien dit veld verplicht is."></v-checkbox></v-col>
								</v-row>
							</v-card>
						</v-col>
						<v-col cols="12" md="4">
							<v-card outlined elevation="0" class="pa-5 h-100">
								<h4>Bedrijfsnaam veld</h4>
								<v-row>
									<v-col cols="6"> <v-checkbox v-model="checkout.companyname" :label="'Toon veld'" hint="Toon de adres velden"></v-checkbox></v-col>
									<v-col cols="6"> <v-checkbox v-model="checkout.companynamerequired" :label="'Verplicht'" hint="Selecteer indien dit veld verplicht is."></v-checkbox></v-col>
									<v-col cols="6"> <v-checkbox v-model="checkout.company_no_vat_move" :label="'Geen btw verleggen'" hint="Selecteer indien dit veld verplicht is."></v-checkbox></v-col>
								</v-row>
							</v-card>
						</v-col>
					</v-row>

					<v-row>
						<v-col cols="12" md="4">
							<v-card outlined elevation="0" class="pa-5 h-100">
								<h4>Opmerking veld</h4>
								<v-row>
									<v-col cols="6"> <v-checkbox v-model="checkout.comment" :label="'Toon veld'" hint="Toon dit veld"></v-checkbox></v-col>
									<v-col cols="6"> <v-checkbox v-model="checkout.commentrequired" :label="'Verplicht'" hint="Selecteer indien dit veld verplicht is."></v-checkbox></v-col>
								</v-row>
							</v-card>
						</v-col>
						<v-col cols="12" md="4">
							<v-card outlined elevation="0" class="pa-5 h-100">
								<h4>Aantal veld</h4>
								<v-row>
									<v-col cols="6"> <v-checkbox v-model="checkout.count" :label="'Toon veld'" hint="Toon dit veld"></v-checkbox></v-col>
									<v-col cols="6"> <v-checkbox v-model="checkout.countrequired" :label="'Verplicht'" hint="Selecteer indien dit veld verplicht is."></v-checkbox></v-col>
								</v-row>
							</v-card>
						</v-col>
					</v-row>

					<v-spacer class="py-5"></v-spacer>
					<h1 class="text-primary">Besteloverzicht</h1>
					<p></p>
					<v-text-field label="Knoptekst" outlined></v-text-field>
					<v-checkbox v-model="checkout.countrequired" :label="'Verberg kortingscode veld'" hint="Selecteer indien dit veld verplicht is."></v-checkbox>
					<v-checkbox v-model="checkout.countrequired" :label="'Verberg btw-bedrag waar mogelijk'" hint="Selecteer indien dit veld verplicht is."></v-checkbox>
					<v-checkbox v-model="checkout.countrequired" :label="'Verberg het BTW-id veld voor alle bestellingen'" hint="Selecteer indien dit veld verplicht is."></v-checkbox>
					<v-checkbox v-model="checkout.countrequired" :label="'Verberg het btw-id veld voor binnenlandse bestellingen'" hint="Selecteer indien dit veld verplicht is."></v-checkbox>

					<v-spacer class="py-5"></v-spacer>
					<h1 class="text-primary">Vindbaarheid</h1>
					<v-text-field label="Paginatitel" outlined :value="`${checkout.name} - ${shop().name}`"></v-text-field>

					<v-row>
						<v-col cols="12" class="ml-auto text-right">
							<v-btn color="secondary" right @click="submitdata" elevation="0"> Opslaan </v-btn>
						</v-col>
					</v-row>
				</v-card>
			</v-container>
		</v-main>
	</v-app>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Topmenubar from "@/components/Topmenubar.vue";
import Subheadertabs from "@/components/Subheadertabs.vue";
import { mapGetters } from "vuex";

export default {
	computed: {
		...mapGetters(["getshop"]),
	},
	components: {
		Sidebar,
		Topmenubar,
		Subheadertabs,
	},

	props: {
		checkout: Object,
		submit: Function,
	},

	methods: {
		submitdata() {
			if (this.submit != null) {
				this.submit();
			}
		},

		shop() {
			return this.getshop
		}
	},

	data() {
		return {
			nameRules: [
				(v) => !!v || "Name is required",
				(v) => /^[a-zA-Z]{2,25}$/.test(v) || "Naam ongeldig",
			],
			allOwnedproducts: ["Product1", "Product2"],
			subheadertabs: [
				{
					text: "Algemeen",
					href: `/checkout/${this.$route.params.id}`,
				},
				{
					text: "Geavanceerd",
					href: `/checkout/${this.$route.params.id}/advanced`,
				},

				{
					text: "One-click upsells",
					href: `/checkout/${this.$route.params.id}/upsells`,
				},
				{
					text: "Layouts",
					href: `/checkout/${this.$route.params.id}/layout`,
				},
				{
					text: "Weergeven",
					href: `/`,
				},
			],

			breadcrumb: [
				{
					text: "Checkouts",
					disabled: false,
					href: "/checkouts",
				},
				{
					text: `${this.$route.params.name}`,
					disabled: true,
					href: `/checkouts/${this.$route.params.id}`,
				},
			],
		};
	},
};
</script>

