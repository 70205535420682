<template>
  <v-app>
    <Sidebar />
    <Topmenubar :breadcrumbs="this.breadcrumb" />
    <v-main>
      <v-container>
      

      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Topmenubar from "@/components/Topmenubar.vue";
export default {
  components: {
    Sidebar,Topmenubar
  },

  data: () => ({
    breadcrumb: [
      {
        text: "Instellingen",
        disabled: false,
        href: "/settings"
      },
      {
        text: "Kyano orders",
        disabled: true,
        href: "/settings/system"
      }
    ]
  })
};
</script>

