<template>
	<v-app>
		<Sidebar />
		<Topmenubar :breadcrumbs="this.breadcrumb" />

		<v-main>
			<Subheadertabs :subheadertabs="this.subheadertabs" ref="subheadertabs" />
			<v-container class="p-5" fluid>
				<v-card elevation="0" class="p-5 h-100">
					<h1 class="text-primary">Facturen</h1>
					<p>Instellingen voor de facturen die de klanten ontvangen.</p>

					<v-row>
						<v-col cols="12">
							<h4>Factuurnummer</h4>
							<p>Factuurnummers worden opgebouwd op basis van prefix, jaar en een volgnummer.</p>
							<v-row>
								<v-col cols="12" md="3">
									<v-text-field v-model="setting.invoiceprefix" label="Prefix" outlined></v-text-field>
								</v-col>
								<v-col cols="12" md="3">
									<v-text-field v-model="setting.invoicenextnumber" label="Volgnummer" outlined></v-text-field>
								</v-col>
								<v-col cols="12" md="3">
									<v-chip v-model="setting.invoiceprefix" label large> </v-chip>
								</v-col>
							</v-row>
							<p>Let op: Bestaande factuurnummers worden niet aangepast.</p>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12">
							<h4>Betalingsherinneringen</h4>
							<p>Voer in na hoeveel dagen je geautomatiseerd herinneringen wilt versturen.</p>
							<v-row>
								<v-col cols="12" md="3">
									<v-text-field v-model="setting.paymentreminder1" label="1e herinnering" outlined></v-text-field>
								</v-col>
								<v-col cols="12" md="3">
									<v-text-field v-model="setting.paymentreminder2" label="2e herinnering" number outlined></v-text-field>
								</v-col>
								<v-col cols="12" md="3">
									<v-text-field v-model="setting.paymentreminder3" label="3e herinnering" number outlined></v-text-field>
								</v-col>
							</v-row>
						</v-col>
						<v-row> </v-row>
						<v-col cols="12" class="ml-auto text-right">
							<v-btn color="secondary" right @click="submit" elevation="0"> Opslaan </v-btn>
						</v-col>
					</v-row>
				</v-card>
			</v-container>
		</v-main>
	</v-app>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Topmenubar from "@/components/Topmenubar.vue";
import Subheadertabs from "@/components/Subheadertabs.vue";

export default {
	components: {
		Sidebar,
		Topmenubar,
		Subheadertabs,
	},

	props: {
		setting: Object,
		submit: Function,
	},

	data() {
		return {
			breadcrumb: [
				{
					text: "Instellingen",
					disabled: false,
					href: "/settings",
				},
				{
					text: "Betalingen",
					disabled: true,
					href: "/settings/payments",
				},
			],
			subheadertabs: [
				{
					text: "Gegevens",
					href: "/settings/general",
				},
				{
					text: "Betalingen",
					href: "/settings/payments",
				},
				{
					text: "Algemene voorwaarden",
					href: "/settings/terms",
				},
				{
					text: "Domeinnaam",
					href: "/settings/domainname",
				},
				{
					text: "Voorkeuren",
					href: "/settings/preferences",
				},
				{
					text: "Widgets",
					href: "/settings/widgets",
				},
			],
		};
	},
	methods: {
		updateactivetab(v, o) {
			var res = this.subheadertabs.filter((item) => item.href === v);
			if (res.length > 0) {
				var res2 = this.breadcrumb.filter((item) => item.href === o);
				res2[0].text = res[0].text;
				res2[0].href = res[0].href;
			}
		},
	},
};
</script>
