<template>
	<v-app>
		<Sidebar />
		<Topmenubar :breadcrumbs="this.breadcrumb" />

		<v-main>
			<Subheadertabs :subheadertabs="this.subheadertabs" ref="subheadertabs" />

			<v-container class="p-5" fluid>
				<v-card elevation="0" class="p-5 h-100">
					<h1 class="text-primary">Domeinnaam</h1>
					<p>Wil je de betaalpagina’s op een eigen (dub)domein hebben. Dan kan je dit hier instellen. Je (sub)domein zal werken zodra de DNS instellingen succesvol zijn ingesteld. Dit kan per hosting verschillen. Gemiddelde wachttijd is 2 tot 3 uur.</p>

					<v-row>
						<v-col cols="12">
							<v-text-field v-model="setting.domainname" prefix="https://" label="Jouw eigen domeinnaam" hint="DNS instellingen worden hieronder getoond." placeholder="Bijv. Afrekenen.domeinnaam.nl" outlined persistent-hint> </v-text-field>
						</v-col>
					</v-row>

					<v-row>
						<v-col cols="12" class="ml-auto text-right">
							<v-btn color="secondary" right @click="submit" elevation="0"> Opslaan </v-btn>
						</v-col>
					</v-row>
				</v-card>
			</v-container>
		</v-main>
	</v-app>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Topmenubar from "@/components/Topmenubar.vue";
import Subheadertabs from "@/components/Subheadertabs.vue";
export default {
	components: {
		Sidebar,
		Topmenubar,
		Subheadertabs,
	},

	props: {
		setting: Object,
		submit: Function,
	},

	data: () => ({
		breadcrumb: [
			{
				text: "Instellingen",
				disabled: false,
				href: "/settings",
			},
			{
				text: "Domeinnaam",
				disabled: true,
				href: "/settings/domainname",
			},
		],
		subheadertabs: [
			{
				text: "Gegevens",
				href: "/settings/general",
			},
			{
				text: "Betalingen",
				href: "/settings/payments",
			},
			{
				text: "Algemene voorwaarden",
				href: "/settings/terms",
			},
			{
				text: "Domeinnaam",
				href: "/settings/domainname",
			},
			{
				text: "Voorkeuren",
				href: "/settings/preferences",
			},
			{
				text: "Widgets",
				href: "/settings/widgets",
			},
		],
	}),
};
</script>

