<template>
  <v-app>
    <Sidebar />
    <Topmenubar :breadcrumbs="this.breadcrumb" />

    <v-main>
      <Subheadertabs
        :subheadertabs="this.subheadertabs"
        ref="subheadertabs"
        :updateactivetab="updateactivetab"
      />

      <v-container class="p-5" fluid>
        <v-card elevation="0" class="p-5 h-100">
          <h1 class="text-primary">Mollie</h1>
          <p>
            Vul hieronder je Mollie API code in. Dit is terug te vinden op de
            developers pagina in de Mollie dashboard.
          </p>

          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="setting.mollieliveapi"
                label="Live API key"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="setting.mollietestapi"
                label="Test API key"
                outlined
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-checkbox
                v-model="setting.mollieteston"
                label="Test modus actief"
                hint="Activeer de test modus om een bestel proces te testen en te ervaren."
                persistent-hint
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="ml-auto text-right">
              <v-btn color="secondary" right @click="submit" elevation="0">
                Opslaan
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Topmenubar from "@/components/Topmenubar.vue";
import Subheadertabs from "@/components/Subheadertabs.vue";

export default {
  components: {
    Sidebar,
    Topmenubar,
    Subheadertabs,
  },

  props: {
    setting: Object,
    submit: Function,
  },
  data() {
    return {
      breadcrumb: [
        {
          text: "Instellingen",
          disabled: false,
          href: "/settings",
        },
        {
          text: "Koppelingen",
          disabled: true,
          href: "/settings",
        },
        {
          text: "Loading",
          disabled: true,
          href: "#",
        },
      ],
      subheadertabs: [
        {
          text: "Webhooks",
          href: "/settings/webhooks",
        },
        {
          text: "Analytics",
          href: "/settings/analytics",
        },
        {
          text: "Betaalmethodes",
          href: "/settings/paymentmethodes",
        },
      ],
    };
  },
  methods: {
    updateactivetab(v, o) {
      var res = this.subheadertabs.filter((item) => item.href === v);
      if (res.length > 0) {
        var res2 = this.breadcrumb.filter((item) => item.href === o);
        res2[0].text = res[0].text;
        res2[0].href = res[0].href;
      }
    },
  },
};
</script>
